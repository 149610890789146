import { fetch } from "helpers";
import { uiActions } from "store/ui";
import * as authTypes from "store/auth/types";
import * as errorTypes from "store/ui/types";
import querystring from "querystring";

const apiMiddleware = ({ dispatch }) => next => action => {
  next(action);
  const { API, label } = action.meta || {};
  const { path, method, data, params } = action.payload || {};

  if (!API) {
    return;
  }

  const url = `${path}${params ? `?${querystring.stringify(params)}` : ""}`;

  // Notify request start with passing "label"
  // for handling multiple loading spinner
  dispatch(uiActions.fetchingStart(label));

  return fetch({ url, method, data })
    .then(res => {
      // Notify request finished
      dispatch(uiActions.fetchingEnd(label));

      return handleResponse(res, action, next);
    })
    .catch(error => {
      // Notify request finished
      dispatch(uiActions.fetchingEnd(label));

      if (error.response) {
        return handleErrors(error.response, action, next);
      } else if (error.request) {
        return handleErrors(error.request, action, next);
      }
      return handleErrors(error.message, action, next);
    });
};

function handleResponse(response, action, next) {
  next({
    type: `${action.type}_COMPLETED`,
    payload: response.data,
    meta: action.meta
  });

  return response;
}

function handleErrors(err, action, next) {
  if (err.status === 401) {
    next({
      type: authTypes.RESET_STORE,
      meta: {
        API: false,
        label: "auth",
        loadMore: false
      }
    });
  } else {
    next({
      type: errorTypes.SET_ERROR_MESSAGE,
      payload: (err.response && err.response.data) || {},
      meta: action.meta
    });
  }

  return err;
}

export default apiMiddleware;
