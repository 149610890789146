import React, { useEffect } from "react";
import { Select, Wrapper, TextField } from "views/ui";
import { useDispatch, useSelector } from "react-redux";
import { supplementActions } from "store/supplements";
import { AddBox, Done } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import { useToggle } from "hooks";
import { withFormik } from "formik";
import { textFormatter } from "utils";

function prepareOptions(categories = []) {
  return categories.map(category => ({
    label: textFormatter.capitalize(category.name),
    value: category.id
  }));
}

function CategoryForm({
  category = {},
  values,
  handleChange,
  handleCategoryAttach
}) {
  const dispatch = useDispatch();
  const { getCategories, createCategory } = supplementActions;
  const [showInput, setShowInput] = useToggle(false);
  const categories = useSelector(({ supplements }) => supplements.categories);

  useEffect(() => {
    dispatch(getCategories());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateCategory = async () => {
    const name = values.createCategory.trim();
    if (name) {
      await dispatch(createCategory({ name: name.toLowerCase() }));
    }
    setShowInput();
  };

  return (
    <Wrapper>
      {!showInput && (
        <Wrapper display="flex" justifyContent="flex-end">
          <IconButton onClick={setShowInput}>
            <AddBox />
          </IconButton>
        </Wrapper>
      )}

      {showInput && (
        <Wrapper display="flex" mb={2}>
          <TextField
            label="Create Category"
            margin="dense"
            variant="outlined"
            width="90%"
            name="createCategory"
            value={values.createCategory}
            rows={4}
            onChange={handleChange}
          />
          <IconButton onClick={handleCreateCategory}>
            <Done />
          </IconButton>
        </Wrapper>
      )}

      <Select
        options={prepareOptions(categories)}
        mr={1}
        defaultValue={
          category && {
            label: textFormatter.capitalize(category.name),
            value: category.id
          }
        }
        name="category"
        onChange={handleCategoryAttach}
      />
    </Wrapper>
  );
}

export default withFormik({
  mapPropsToValues: ({ category: categoryObj }) => {
    const createCategory = "";
    const category = (categoryObj && categoryObj.id) || "";

    return {
      createCategory,
      category
    };
  }
})(CategoryForm);
