import * as types from "./types";

export const getLeads = params => {
  return {
    type: types.GET_LEADS,
    payload: {
      path: `leads`,
      method: "GET",
      params
    },
    meta: {
      API: true,
      jwt: true,
      label: "leads",
      loadMore: false
    }
  };
};

export const getLeadsByGroup = params => {
  return {
    type: types.GET_LEADS_BY_GROUP,
    payload: {
      path: `leads/group-by`,
      method: "GET",
      params
    },
    meta: {
      API: true,
      jwt: true,
      label: "leads",
      loadMore: false
    }
  };
};

export const getLeadsForExport = params => {
  return {
    type: types.GET_LEADS_FOR_EXPORT,
    payload: {
      path: `leads`,
      method: "GET",
      params
    },
    meta: {
      API: true,
      jwt: true,
      label: "leadsForExport",
      loadMore: false
    }
  };
};

export const updateLeads = (id, data) => {
  return {
    type: types.UPDATE_LEADS,
    payload: {
      path: `/leads/${id}`,
      method: "PUT",
      data
    },
    meta: {
      API: true,
      jwt: true,
      label: "updateLeads",
      loadMore: false
    }
  };
};

export const updateGroups = data => {
  return {
    type: types.UPDATE_LEADS_GROUP,
    payload: {
      path: `/leads/groups`,
      method: "PUT",
      data
    },
    meta: {
      API: true,
      jwt: true,
      label: "updateLeads",
      loadMore: false
    }
  };
};

export const restrictLeads = data => {
  return {
    type: types.RESTRICT_LEADS,
    payload: {
      path: `restricted`,
      method: "POST",
      data
    },
    meta: {
      API: true,
      jwt: true,
      label: "restricted",
      loadMore: false
    }
  };
};

export const deleteRestrictLeads = (leadId, data) => {
  return {
    type: types.DELETE_RESTRICT_LEADS,
    payload: {
      path: `restricted/leads/${leadId}`,
      method: "POST",
      data
    },
    meta: {
      API: true,
      jwt: true,
      label: "deleteRestrictLeads",
      loadMore: false
    }
  };
};

export const getRestrictLead = leadId => {
  return {
    type: types.GET_RESTRICTED_LEADS,
    payload: {
      path: `restricted/leads/${leadId}`,
      method: "GET"
    },
    meta: {
      API: true,
      jwt: true,
      label: "getRestrictLead",
      loadMore: false
    }
  };
};

export const getEmailTemplates = () => {
  return {
    type: types.GET_EMAIL_TEMPLATES,
    payload: {
      path: `email/get-templates`,
      method: "GET"
    },
    meta: {
      API: true,
      jwt: true,
      label: "getEmailTemplates",
      loadMore: false
    }
  };
};

export const sendCampaign = data => {
  return {
    type: types.SEND_CAMPAIGN,
    payload: {
      path: `email/campaign`,
      method: "POST",
      data
    },
    meta: {
      API: true,
      jwt: true,
      label: "getEmailTemplates",
      loadMore: false
    }
  };
};
