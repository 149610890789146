import React from "react";
import styled from "styled-components";

const PlaceholderWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

export default function Placeholder({ placeholder = "", width, height }) {
  const image =
    width && height
      ? placeholder.replace(
          /(viewBox=").*?"/,
          `viewBox="0 0 ${width} ${height}"`
        )
      : placeholder;

  return (
    <PlaceholderWrapper
      dangerouslySetInnerHTML={{ __html: image }}
    ></PlaceholderWrapper>
  );
}
