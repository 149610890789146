import { imageSizes, assetsEndpoint } from "helpers/variables";

export function getImage(availableVersions, sizes, preferredVersion) {
  const sizeIndex = sizes.findIndex(
    imageSize => imageSize === preferredVersion
  );
  const imageSizes = sizes.slice(sizeIndex);
  for (let size of imageSizes) {
    if (availableVersions.includes(size)) {
      return size;
    }
  }
}

export function getPath(imageMeta, preferredVersion = "300") {
  const size = getImage(
    imageMeta.availableVersions,
    imageSizes,
    preferredVersion
  );

  return `_${size}.${imageMeta.ext}`;
}

export function getCopyPath(image, version, index) {
  const ext = image.imageMeta.ext;
  let imageExt;

  if (ext === "gif") {
    imageExt = "gif";
  }

  if (ext === "png") {
    imageExt = "png";
  }

  if (ext === "jpeg") {
    imageExt = index % 2 === 0 ? "webp" : "jpeg";
  }

  if (index === 0) {
    imageExt = "svg";
  }

  return {
    path: `${assetsEndpoint}/${image.imageId}/${image.imageId}_${version}.${imageExt}`,
    name: `${version} (${imageExt})`
  };
}
