export const GET_GALLERIES = "UPLOAD/GET_GALLERIES";
export const GET_GALLERIES_COMPLETED = "UPLOAD/GET_GALLERIES_COMPLETED";
export const GET_GALLERIES_FAILED = "UPLOAD/GET_GALLERIES_FAILED";

export const UPDATE_IMAGE = "UPLOAD/UPDATE_IMAGE";
export const UPDATE_IMAGE_COMPLETED = "UPLOAD/UPDATE_IMAGE_COMPLETED";
export const UPDATE_IMAGE_FAILED = "UPLOAD/UPDATE_IMAGE_FAILED";

export const UPDATE_MULTIPLE_IMAGES = "UPLOAD/UPDATE_MULTIPLE_IMAGES";
export const UPDATE_MULTIPLE_IMAGES_COMPLETED =
  "UPLOAD/UPDATE_MULTIPLE_IMAGES_COMPLETED";
export const UPDATE_MULTIPLE_IMAGES_FAILED =
  "UPLOAD/UPDATE_MULTIPLE_IMAGES_FAILED";

export const UPLOAD_IMAGE = "UPLOAD/UPLOAD_IMAGE";
export const UPLOAD_IMAGE_COMPLETED = "UPLOAD/UPLOAD_IMAGE_COMPLETED";
export const UPLOAD_IMAGE_FAILED = "UPLOAD/UPLOAD_IMAGE_FAILED";

export const DELETE_IMAGE = "UPLOAD/DELETE_IMAGE";
export const DELETE_IMAGE_COMPLETED = "UPLOAD/DELETE_IMAGE_COMPLETED";
export const DELETE_IMAGE_FAILED = "UPLOAD/DELETE_IMAGE_FAILED";

export const DOWNLOAD_IMAGE = "UPLOAD/DOWNLOAD_IMAGE";
export const DOWNLOAD_IMAGE_COMPLETED = "UPLOAD/DOWNLOAD_IMAGE_COMPLETED";
export const DOWNLOAD_IMAGE_FAILED = "UPLOAD/DOWNLOAD_IMAGE_FAILED";

export const CREATE_IMAGE_TAG = "UPLOAD/CREATE_IMAGE_TAG";
export const CREATE_IMAGE_TAG_COMPLETED = "UPLOAD/CREATE_IMAGE_TAG_COMPLETED";
export const CREATE_IMAGE_TAG_FAILED = "UPLOAD/CREATE_IMAGE_TAG_FAILED";

export const GET_IMAGE_TAGS = "UPLOAD/GET_IMAGE_TAGS";
export const GET_IMAGE_TAGS_COMPLETED = "UPLOAD/GET_IMAGE_TAGS_COMPLETED";
export const GET_IMAGE_TAGS_FAILED = "UPLOAD/GET_IMAGE_TAGS_FAILED";
