import React, { useEffect } from "react";
import BlogForm from "./BlogForm";
import { useDispatch, useSelector } from "react-redux";
import { blogActions } from "store/blogs";
import { useHistory } from "react-router-dom";

const BlogAddContainer = () => {
  const dispatch = useDispatch();
  const { createBlog, emptyBlogData } = blogActions;
  const blog = useSelector(({ blogs }) => blogs.blog);
  const loading = useSelector(({ ui }) => ui.loading["blogs"]);
  const history = useHistory();

  useEffect(() => {
    if (blog.id) {
      history.push(`/blogs/${blog.id}`);
    }
    return () => {
      dispatch(emptyBlogData());
    };
  }, [blog.id, dispatch, emptyBlogData, history]);

  const handleBlogUpdate = (type, data) => {
    const status = type === "draft" ? "draft" : "published";

    const updatedData = {
      ...data,
      status,
      site: "orocube"
    };

    dispatch(createBlog(updatedData));
  };

  return <BlogForm handleBlogUpdate={handleBlogUpdate} loading={loading} />;
};

export default BlogAddContainer;
