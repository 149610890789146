import React from "react";
import { Paper, Typography, Wrapper } from "views/ui";
import {
  IconButton,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText
} from "@material-ui/core";
import { Button, Confirm } from "views/ui";
import { Edit, PlaylistAdd, Delete, Link } from "@material-ui/icons";
import SubSection from "./SubSection";
import { useToggle } from "hooks";

const SiteSection = ({
  section = {},
  index,
  handleSiteSectionEdit,
  handleSiteSectionCreate,
  handleSiteSectionDelete
}) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useToggle(false);

  const handleConfirm = () => {
    handleSiteSectionDelete(section.id);
    setOpenConfirmDialog(false);
  };

  return (
    <Paper p={4}>
      <Wrapper display="flex" justifyContent="flex-end">
        <IconButton onClick={handleSiteSectionCreate}>
          <PlaylistAdd />
        </IconButton>
        <IconButton
          aria-label="delete"
          onClick={() => handleSiteSectionEdit(index)}
        >
          <Edit />
        </IconButton>
        <IconButton aria-label="delete" onClick={setOpenConfirmDialog}>
          <Delete />
        </IconButton>
      </Wrapper>

      <Typography variant="h2" mb={3} align="center">
        {section.title}
      </Typography>

      {section.subtitles &&
        section.subtitles.map((subtitle, index) => (
          <Typography variant="subtitle1" mb={1} key={index} align="center">
            {subtitle}
          </Typography>
        ))}

      <Typography
        variant="body1"
        mb={1}
        dangerouslySetInnerHTML={{ __html: section.description }}
      />

      <Wrapper my={6}>
        <Grid container spacing={2}>
          {section.subSections &&
            section.subSections.map((subSection, index) => (
              <Grid item xs={6} key={index}>
                <SubSection subSection={subSection} showEdit={false} />
              </Grid>
            ))}
        </Grid>
      </Wrapper>

      <Grid container>
        {section.collections &&
          section.collections.map(
            ({ title, collectionItems = [] }, index) =>
              collectionItems && (
                <Grid item xs={6} key={index} mt={3}>
                  <Typography variant="h3" mb={1} transform="capitalize">
                    {title}
                  </Typography>
                  <List>
                    {collectionItems.map(listItem => (
                      <ListItem key={listItem._id}>
                        <ListItemAvatar>
                          <Avatar>{/* <ImageIcon /> */}</Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={listItem.title} />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              )
          )}
      </Grid>
      <Wrapper mt={4}>
        {section.links &&
          section.links.map((link, index) => (
            <>
              {link && (
                <Button size="small" key={index} href={link.url}>
                  <Link /> &nbsp; {link.label}
                </Button>
              )}
            </>
          ))}
      </Wrapper>

      <Confirm
        open={openConfirmDialog}
        handleClose={setOpenConfirmDialog}
        handleConfirm={handleConfirm}
      />
    </Paper>
  );
};

export default SiteSection;
