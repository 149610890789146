import React from 'react';
import { Wrapper } from 'views/ui';
import Leads from 'views/components/Leads';

export default function LeadsPage() {
  return (
    <Wrapper p={2}>
      <Leads />
    </Wrapper>
  );
}
