import React from "react";
import { Select } from "../../";

const SelectFilter = ({
  name,
  value,
  placeholder,
  handleChange,
  isMulti = false,
  options = [],
  width = 150
}) => {
  return (
    <Select
      style={{ width: width }}
      value={value}
      isMulti={isMulti}
      placeholder={placeholder}
      name={name}
      onChange={handleChange}
      options={options}
    />
  );
};

export default SelectFilter;
