import React from "react";

import withSVG from "./withSVG";

const CalenderIcon = () => (
  <path d="M107.728.001C48.325.001 0 48.329 0 107.732c.005 59.397 48.332 107.722 107.728 107.722 59.401 0 107.728-48.324 107.728-107.723C215.455 48.329 167.129.001 107.728.001zm0 15c22.021 0 42.269 7.721 58.192 20.592L35.593 165.919C22.724 149.997 15.002 129.75 15 107.731c0-51.131 41.598-92.73 92.728-92.73zm0 185.453c-23.718 0-45.381-8.956-61.797-23.658L176.796 45.931c14.703 16.416 23.659 38.081 23.659 61.801 0 51.127-41.598 92.722-92.727 92.722z" />
);

export default withSVG(CalenderIcon, {
  viewBox: "0 0 215.455 215.455",
  size: 215.455
});
