import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useToggle } from 'hooks';
import { featureActions } from 'store/features';
import { Fetcher } from 'utils';
import Features from './Features';
import FeatureForm from './FeatureForm';

const FeaturesContainer = () => {
  const features = useSelector(({ features }) => features.features);
  const { loading } = useSelector(({ ui }) => ({
    loading: ui.loading,
  }));
  const dispatch = useDispatch();
  const [feature, setFeature] = useState({});
  const [open, handleToggleChange] = useToggle(false);
  const { getFeatures, updateFeature, createFeature } = featureActions;

  const handleSelectFeature = feature => {
    setFeature(feature);
    handleToggleChange();
  };

  const handleCloseFeatureForm = () => {
    setFeature({});
    handleToggleChange();
  };

  const handleFeatureUpdate = data => {
    if (feature._id) {
      dispatch(updateFeature(feature._id, data));
    } else {
      dispatch(createFeature(data));
      handleToggleChange();
    }
  };

  return (
    <Fetcher fetchData={getFeatures} label="getFeatures">
      {() => (
        <>
          <FeatureForm
            open={open}
            feature={feature}
            handleCloseFeatureForm={handleCloseFeatureForm}
            handleFeatureUpdate={handleFeatureUpdate}
            loading={loading}
          />
          <Features
            features={features}
            handleSelectFeature={handleSelectFeature}
            handleToggleChange={handleToggleChange}
          />
        </>
      )}
    </Fetcher>
  );
};

export default FeaturesContainer;
