import React from 'react';

import withSVG from './withSVG';

const LogoutIcon = () => (
    <path d="M 64 8 C 63.2 8 62.500391 8.3003906 61.900391 8.9003906 L 46.900391 23.900391 C 46.300391 24.500391 46 25.2 46 26 C 46 26.8 46.300391 27.499609 46.900391 28.099609 C 48.100391 29.299609 49.999609 29.299609 51.099609 28.099609 L 61 18.199219 L 61 49 C 61 50.7 62.3 52 64 52 C 65.7 52 67 50.7 67 49 L 67 18.199219 L 76.900391 28.099609 C 78.100391 29.299609 79.999609 29.299609 81.099609 28.099609 C 82.299609 26.899609 82.299609 25.000391 81.099609 23.900391 L 66.099609 8.9003906 C 65.499609 8.3003906 64.8 8 64 8 z M 25.0625 22.099609 C 24.3 22.099609 23.55 22.4 23 23 C 0.4 45.6 0.4 82.4 23 105 C 45.6 127.6 82.4 127.6 105 105 C 116.3 93.7 122 78.8 122 64 C 122 49.2 116.3 34.3 105 23 C 103.8 21.8 101.90078 21.8 100.80078 23 C 99.600781 24.2 99.600781 26.099219 100.80078 27.199219 C 121.10078 47.499219 121.10078 80.499219 100.80078 100.69922 C 80.500781 120.99922 47.500781 120.99922 27.300781 100.69922 C 7.0007813 80.499219 6.9992188 47.499219 27.199219 27.199219 C 28.399219 25.999219 28.399219 24.1 27.199219 23 C 26.599219 22.4 25.825 22.099609 25.0625 22.099609 z M 64 61 A 3 3 0 0 0 61 64 A 3 3 0 0 0 64 67 A 3 3 0 0 0 67 64 A 3 3 0 0 0 64 61 z"/>
  );


export default withSVG(LogoutIcon, {viewBox: "0 0 128 128", fill: "#e57373"});
