import React from 'react';
import { Collapse } from '@material-ui/core';

import Features from 'views/components/Features';
import { useToggle } from 'hooks';
import { Divider, Wrapper, PageHeader, Paper } from 'views/ui';

const FeaturesPage = () => {
  const [open, handleToggleChange] = useToggle(true);

  return (
    <Wrapper>
      <PageHeader
        title="Feature List"
        handleToggleChange={handleToggleChange}
      />
      <Collapse in={open}></Collapse>
      <Divider my={3} />
      <Paper elevation={2}>
        <Wrapper p={3}>
          <Features />
        </Wrapper>
      </Paper>
    </Wrapper>
  );
};

export default FeaturesPage;
