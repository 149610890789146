import React from "react";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import Dashboard from "views/pages/Dashboard";
import UsersPage from "views/pages/UsersPage";
import LoginPage from "views/pages/LoginPage";
import FeaturesPage from "views/pages/FeaturesPage";
import SitesPage from "views/pages/SitesPage";
import GalleriesPage from "views/pages/GalleriesPage";
import LeadsPage from "views/pages/LeadsPage";
import LeadsByGroup from "views/pages/LeadsByGroup";
import BlogPage from "views/pages/BlogPage";
import BlogEditPage from "views/pages/BlogEditPage";
import BlogAddPage from "views/pages/BlogAddPage";
import SignupPage from "views/pages/SignupPage";
import GalleryPageV2 from "views/pages/GalleryPageV2";
import Layout from "views/layouts/Layout";

const PrivateRoute = ({ component: Component, ...props }) => {
  const auth = useSelector(({ auth }) => auth.jwt);

  return (
    <Route
      {...props}
      render={innerProps =>
        auth ? <Component {...innerProps} /> : <Redirect to="/" />
      }
    />
  );
};

const Routes = () => (
  <BrowserRouter>
    <Layout>
      <Switch>
        <Route path="/" component={LoginPage} exact />
        <PrivateRoute path="/dashboard" component={Dashboard} exact />
        <PrivateRoute path="/signup" component={SignupPage} exact />
        <PrivateRoute path="/sites/:slug" component={SitesPage} exact />
        <PrivateRoute path="/users" component={UsersPage} exact />
        <PrivateRoute path="/leads" component={LeadsPage} exact />
        <PrivateRoute path="/leads-by-group" component={LeadsByGroup} exact />
        <PrivateRoute path="/blogs" component={BlogPage} exact />
        <PrivateRoute path="/blogs/add" component={BlogAddPage} exact />
        <PrivateRoute path="/blogs/:id" component={BlogEditPage} exact />
        <PrivateRoute path="/features" component={FeaturesPage} exact />
        <PrivateRoute path="/galleries" component={GalleriesPage} exact />
        <PrivateRoute path="/galleries-v2" component={GalleryPageV2} exact />
      </Switch>
    </Layout>
  </BrowserRouter>
);

export default Routes;
