import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { authActions } from "store/auth";
import { supplementActions } from "store/supplements";
import Signup from "./Signup";

const SignupContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { signup } = authActions;
  const { getRoles } = supplementActions;
  const roles = useSelector(({ supplements }) => supplements.roles);
  const [signupError, setSignupError] = useState("");
  const { loading } = useSelector(({ ui }) => ({
    loading: ui.loading
  }));

  useEffect(() => {
    dispatch(getRoles());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSignup = async values => {
    try {
      const response = await dispatch(signup(values));
      if (response.status === "success") {
        history.push("/users");
      } else {
        setSignupError(
          response.message ? response.message.title : "Something went wrong"
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Signup
      loading={loading.auth}
      handleSignup={handleSignup}
      roles={roles}
      signupError={signupError}
    />
  );
};

export default SignupContainer;
