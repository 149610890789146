import axios from "axios";
const apiEndpoint = process.env.REACT_APP_API;

export const instance = axios.create({
  baseURL: apiEndpoint
});

const fetch = async ({ url, method, data, params, type = "service" }) => {
  const options = {
    method,
    data,
    params
  };
  const res =
    type === "S3Upload" ? await instance.put() : await instance(url, options);
  return parseStatus(res.status, getJSON(res));
};

function parseStatus(status, res) {
  return new Promise((resolve, reject) => {
    if (status >= 200 && status < 300) {
      res.then(response => resolve(response));
    } else {
      res.then(response => {
        return reject({ status, message: response });
      });
    }
  });
}

async function getJSON(response) {
  if (response.status === 204) return "";

  try {
    const data = await response.data; // Parse it as text
    return data;
  } catch (err) {
    return err;
  }
}

// function requestHeaders(token, type) {
//   if (type && type === "multipart") {
//     return {
//       Authorization: token ? `${token}` : ""
//     };
//   }

//   return {
//     Accept: "application/json",
//     "Content-Type": "application/json",
//     Authorization: token ? `${token}` : ""
//   };
// }

export default fetch;
