import * as types from "./types";

export const getCategories = () => {
  return {
    type: types.GET_CATEGORIES,
    payload: {
      path: `categories`,
      method: "GET"
    },
    meta: {
      API: true,
      label: "categories",
      loadMore: false,
      firebase: true
    }
  };
};

export const createCategory = data => {
  return {
    type: types.CREATE_CATEGORY,
    payload: {
      path: `categories`,
      method: "POST",
      data
    },
    meta: {
      API: true,
      label: "categories"
    }
  };
};

export const getCountries = () => {
  return {
    type: types.GET_COUNTRIES,
    payload: {
      path: `countries`,
      method: "GET"
    },
    meta: {
      API: true,
      jwt: true,
      label: "countries",
      loadMore: false
    }
  };
};

export const getBusinessTypes = () => {
  return {
    type: types.GET_BUSINESSTYPES,
    payload: {
      path: `business-types`,
      method: "GET"
    },
    meta: {
      API: true,
      jwt: true,
      label: "businessTypes",
      loadMore: false
    }
  };
};

export const getRoles = () => {
  return {
    type: types.GET_ROLES,
    payload: {
      path: `roles`,
      method: "GET"
    },
    meta: {
      API: true,
      jwt: true,
      label: "businessTypes",
      loadMore: false
    }
  };
};
