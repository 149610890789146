import React, { useState } from "react";
import {
  Wrapper,
  TextField,
  Paper,
  Switch,
  Typography,
  Button,
  Confirm
} from "views/ui";
import { Form, withFormik } from "formik";
import { IconButton } from "@material-ui/core";
import { PlaylistAdd, RemoveCircle } from "@material-ui/icons";
import { useToggle } from "hooks";

const SitePageForm = ({ loading, values, setFieldValue, handleChange }) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useToggle(false);
  const [pageIndex, setPageIndex] = useState(null);

  const handleAddMore = () => {
    setFieldValue(
      "pages",
      values.pages.concat({
        label: "",
        url: "",
        order: 0,
        isActive: false
      })
    );
  };

  const handleRemove = position => {
    setPageIndex(position);
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setPageIndex(null);
    setOpenConfirmDialog(false);
  };

  const handleConfirm = () => {
    const updatedPages = values.pages.filter((_, index) => index !== pageIndex);
    setFieldValue("pages", updatedPages);
    setOpenConfirmDialog(false);
  };

  return (
    <Paper p={3}>
      <Typography variant="h4" align="center" mb={2}>
        Site Pages
      </Typography>
      <Form>
        <Wrapper display="flex" alignItems="center">
          <Wrapper width="95%">
            {values.pages.map((page, index) => (
              <Wrapper
                display="flex"
                key={index}
                name={`pages[${index}]`}
                mb={2}
              >
                <Switch
                  name={`pages[${index}].isActive`}
                  value={page.isActive}
                  onChange={(name, value) =>
                    setFieldValue(`pages[${index}].isActive`, value)
                  }
                />

                <TextField
                  label="Label"
                  margin="dense"
                  variant="outlined"
                  width="200"
                  name={`pages[${index}].label`}
                  value={page.label}
                  rows={4}
                  mr={1}
                  onChange={handleChange}
                />
                <TextField
                  label="Slug"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  name={`pages[${index}].slug`}
                  value={page.slug}
                  rows={4}
                  onChange={handleChange}
                />

                {index === values.pages.length - 1 && (
                  <IconButton onClick={handleAddMore}>
                    <PlaylistAdd />
                  </IconButton>
                )}
                {index !== values.pages.length - 1 && (
                  <IconButton onClick={() => handleRemove(index)}>
                    <RemoveCircle />
                  </IconButton>
                )}
              </Wrapper>
            ))}
          </Wrapper>
        </Wrapper>

        <Wrapper display="flex" justifyContent="flex-end">
          <Button
            // loading={loading["siteForm"]}
            // disabled={loading["siteForm"]}
            variant="contained"
            color="primary"
            mt={2}
            type="submit"
            size="small"
          >
            Update Page
          </Button>
        </Wrapper>
      </Form>

      <Confirm
        open={openConfirmDialog}
        handleClose={handleCloseConfirmDialog}
        handleConfirm={handleConfirm}
      />
    </Paper>
  );
};

export default withFormik({
  mapPropsToValues: ({ pages = [] }) => ({
    pages: pages.length
      ? pages
      : [
          {
            label: "",
            slug: "",
            isActive: false
          }
        ]
  }),

  handleSubmit: (values, { props: { handleSitePage } }) => {
    handleSitePage(values);
  }
})(SitePageForm);
