import * as types from './types';

export const getFeatures = ({ param = {} } = {}) => {
  return {
    type: types.GET_FEATURES,
    payload: {
      path: `features`,
      method: 'GET',
      data: '',
      param,
    },
    meta: {
      API: true,
      jwt: true,
      label: 'getFeatures',
      loadMore: false,
    },
  };
};

export const updateFeature = (id, data) => {
  return {
    type: types.UPDATE_FEATURE,
    payload: {
      path: `features/${id}`,
      method: 'PUT',
      data,
    },
    meta: {
      API: true,
      jwt: true,
      label: 'featureForm',
      loadMore: false,
    },
  };
};

export const createFeature = data => {
  return {
    type: types.CREATE_FEATURE,
    payload: {
      path: `features`,
      method: 'POST',
      data,
    },
    meta: {
      API: true,
      jwt: true,
      label: 'featureForm',
      loadMore: false,
    },
  };
};
