import { ImageSideButton, Block, addNewBlock } from "medium-draft";
import { connect } from "react-redux";
import { galleryActions } from "store/gallery";
import { variables } from "helpers";
import { getPath } from "../../../components/ImageDetails/getImageInfo";

class UploadImage extends ImageSideButton {
  onChange = async e => {
    const { uploadImage } = this.props;
    const file = e.target.files[0];
    if (file.type.indexOf("image/") === 0) {
      const formData = new FormData();
      formData.append("file", file);

      const response = await uploadImage(formData);

      if (response.status === "success" && response.data.imageId) {
        const imageId = response.data.imageId;
        const path = getPath(response.data.imageMeta, "original");
        const imageUrl = `${variables.assetsEndpoint}/${imageId}/${
          imageId + path
        }`;

        this.props.setEditorState(
          addNewBlock(this.props.getEditorState(), Block.IMAGE, {
            src: imageUrl
          })
        );
      }
    }
    this.props.close();
  };
}

const mapStateToProps = ({ auth }) => ({
  jwt: auth.jwt
});

const mapActionToProps = {
  uploadImage: galleryActions.uploadImage
};

export default connect(mapStateToProps, mapActionToProps)(UploadImage);
