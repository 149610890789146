import React, { useState } from 'react';
import { Popover } from '@material-ui/core';
import {
  getDateObject,
  getFormattedDate,
  calculateDates,
  rangeOptions,
} from './util';
import styled from 'styled-components';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar } from 'react-modern-calendar-datepicker';
import { Button } from '../../';

const DateFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-bottom: 10px !important;
  margin-top: -30px !important;

  button {
    text-transform: capitalize;
  }
`;

const DateFilter = ({
  initDates,
  initRange,
  id,
  anchorEl,
  handleDateFilterClose,
}) => {
  const [range, setRange] = useState(initRange);
  const [dates, setDates] = useState({
    from: initDates.startDate ? getFormattedDate(initDates.startDate) : null,
    to: initDates.endDate ? getFormattedDate(initDates.endDate) : null,
  });

  const handlePopoverEnter = () => {
    setRange(initRange);
    setDates({
      from: initDates.startDate ? getFormattedDate(initDates.startDate) : null,
      to: initDates.endDate ? getFormattedDate(initDates.endDate) : null,
    });
  };

  const handlePopoverClose = () => {
    const { from, to } = dates;
    let formattedDates = {};

    if (from) {
      formattedDates = {
        startDate: getDateObject(from),
        endDate: getDateObject(to || from),
      };
    }
    handleDateFilterClose(formattedDates, range);
  };

  const handleRange = (range) => {
    setRange(range);
    const { startDate, endDate } = calculateDates(range);
    setDates({
      from: getFormattedDate(startDate),
      to: getFormattedDate(endDate),
    });
  };

  return (
    <Popover
      id={id}
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={handlePopoverClose}
      onEnter={handlePopoverEnter}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      classes={{ paper: 'overflow-y-hidden' }}
    >
      <Calendar
        maximumDate={getFormattedDate(new Date())}
        value={dates}
        onChange={setDates}
        renderFooter={() => (
          <DateFooter>
            {rangeOptions.map((dateRange) => (
              <Button
                style={{ width: 100, height: 26, padding: 0 }}
                size="small"
                key={dateRange.value}
                variant="outlined"
                color={dateRange.value === range ? 'primary' : 'default'}
                onClick={() => handleRange(dateRange.value)}
              >
                {dateRange.label}
              </Button>
            ))}
          </DateFooter>
        )}
      />
    </Popover>
  );
};

export default DateFilter;
