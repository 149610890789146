import React from "react";
import { Button, Wrapper, Radio } from "views/ui";

export const groups = [
  {
    label: "Ungrouped",
    value: 0
  },
  {
    label: "Lead",
    value: 1
  },
  {
    label: "Prospect",
    value: 2
  },
  {
    label: "Customer",
    value: 3
  }
];

const LeadGroups = ({ handleChange, loading, handleSubmit, value }) => {
  return (
    <Wrapper textAlign="center">
      <Wrapper display="flex" flexDirection="column">
        <Radio value={value} options={groups} handleChange={handleChange} />
      </Wrapper>

      <Button
        fullWidth
        variant="contained"
        color="primary"
        justify="flex-start"
        mt={2}
        type="submit"
        disabled={loading}
        loading={loading}
        size="small"
        onClick={handleSubmit}
      >
        Add to Group
      </Button>
    </Wrapper>
  );
};

export default LeadGroups;
