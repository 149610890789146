import * as types from "./types";

export const uploadImage = data => {
  return {
    type: types.UPLOAD_IMAGE,
    payload: {
      path: `galleries`,
      method: "POST",
      data
    },
    meta: {
      API: true,
      jwt: true,
      label: "uploadImage",
      loadMore: false
    }
  };
};

export const getGalleries = ({ param = {} } = {}) => {
  return {
    type: types.GET_GALLERIES,
    payload: {
      path: `galleries`,
      method: "GET",
      data: "",
      param
    },
    meta: {
      API: true,
      jwt: true,
      label: "getGalleries",
      loadMore: false
    }
  };
};

export const updateImage = (id, data) => {
  return {
    type: types.UPDATE_IMAGE,
    payload: {
      path: `uploads/galleries/${id}`,
      method: "PUT",
      data
    },
    meta: {
      API: true,
      jwt: true,
      label: "updateImage",
      loadMore: false
    }
  };
};

export const deleteImage = id => {
  return {
    type: types.DELETE_IMAGE,
    payload: {
      path: `uploads/galleries/${id}`,
      method: "DELETE"
    },
    meta: {
      API: true,
      jwt: true,
      label: "deleteImage",
      loadMore: false
    }
  };
};

export const createImageTag = data => {
  return {
    type: types.CREATE_IMAGE_TAG,
    payload: {
      path: `image-tags`,
      method: "POST",
      data
    },
    meta: {
      API: true,
      jwt: true,
      label: "createImageTag",
      loadMore: false
    }
  };
};

export const getImageTags = data => {
  return {
    type: types.GET_IMAGE_TAGS,
    payload: {
      path: `image-tags`,
      method: "GET",
      data
    },
    meta: {
      API: true,
      jwt: true,
      label: "getImageTags",
      loadMore: false
    }
  };
};
