import * as types from "./types";

export const uploadImage = data => {
  return {
    type: types.UPLOAD_IMAGE,
    payload: {
      path: `uploads/galleries`,
      method: "POST",
      data
    },
    meta: {
      API: true,
      jwt: true,
      label: "uploadImage",
      loadMore: false
    }
  };
};

export const uploadMultipleImages = data => {
  return {
    type: types.UPDATE_MULTIPLE_IMAGES,
    payload: {
      path: `uploads/multiple-galleries`,
      method: "POST",
      data
    },
    meta: {
      API: true,
      jwt: true,
      label: "uploadImage",
      loadMore: false
    }
  };
};

export const downloadImage = data => {
  return {
    type: types.DOWNLOAD_IMAGE,
    payload: {
      path: `uploads/external`,
      method: "POST",
      data
    },
    meta: {
      API: true,
      jwt: true,
      label: "downloadImage",
      loadMore: false
    }
  };
};

export const getGalleries = ({ param = {} } = {}) => {
  return {
    type: types.GET_GALLERIES,
    payload: {
      path: `uploads/galleries`,
      method: "GET",
      data: "",
      param
    },
    meta: {
      API: true,
      jwt: true,
      label: "getGalleries",
      loadMore: false
    }
  };
};

export const updateImage = (id, data) => {
  return {
    type: types.UPDATE_IMAGE,
    payload: {
      path: `uploads/galleries/${id}`,
      method: "PUT",
      data
    },
    meta: {
      API: true,
      jwt: true,
      label: "updateImage",
      loadMore: false
    }
  };
};

export const deleteImage = id => {
  return {
    type: types.DELETE_IMAGE,
    payload: {
      path: `uploads/galleries/${id}`,
      method: "DELETE"
    },
    meta: {
      API: true,
      jwt: true,
      label: "deleteImage",
      loadMore: false
    }
  };
};
