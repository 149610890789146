export const GET_LEADS = "LEADS/GET_LEADS";
export const GET_LEADS_COMPLETED = "LEADS/GET_LEADS_COMPLETED";
export const GET_LEADS_FAILED = "LEADS/GET_LEADS_FAILED";

export const GET_LEADS_BY_GROUP = "LEADS/GET_LEADS_BY_GROUP";
export const GET_LEADS_BY_GROUP_COMPLETED =
  "LEADS/GET_LEADS_BY_GROUP_COMPLETED";
export const GET_LEADS_BY_GROUP_FAILED = "LEADS/GET_LEADS_BY_GROUP_FAILED";

export const GET_LEADS_FOR_EXPORT = "LEADS/GET_LEADS_FOR_EXPORT";
export const GET_LEADS_FOR_EXPORT_COMPLETED =
  "LEADS/GET_LEADS_FOR_EXPORT_COMPLETED";
export const GET_LEADS_FOR_EXPORT_FAILED = "LEADS/GET_LEADS_FOR_EXPORT_FAILED";

export const RESTRICT_LEADS = "LEADS/RESTRICT_LEADS";
export const RESTRICT_LEADS_COMPLETED = "LEADS/RESTRICT_LEADS_COMPLETED";
export const RESTRICT_LEADS_FAILED = "LEADS/RESTRICT_LEADS_FAILED";

export const UPDATE_LEADS = "LEADS/UPDATE_LEADS";
export const UPDATE_LEADS_COMPLETED = "LEADS/UPDATE_LEADS_COMPLETED";
export const UPDATE_LEADS_FAILED = "LEADS/UPDATE_LEADS_FAILED";

export const UPDATE_LEADS_GROUP = "LEADS/UPDATE_LEADS_GROUP";
export const UPDATE_LEADS_GROUP_COMPLETED =
  "LEADS/UPDATE_LEADS_GROUP_COMPLETED";
export const UPDATE_LEADS_GROUP_FAILED = "LEADS/UPDATE_LEADS_GROUP_FAILED";

export const DELETE_RESTRICT_LEADS = "LEADS/DELETE_RESTRICT_LEADS";
export const DELETE_RESTRICT_LEADS_COMPLETED =
  "LEADS/DELETE_RESTRICT_LEADS_COMPLETED";
export const DELETE_RESTRICT_LEADS_FAILED =
  "LEADS/DELETE_RESTRICT_LEADS_FAILED";

export const GET_RESTRICTED_LEADS = "LEADS/GET_RESTRICTED_LEADS";
export const GET_RESTRICTED_LEADS_COMPLETED =
  "LEADS/GET_RESTRICTED_LEADS_COMPLETED";
export const GET_RESTRICTED_LEADS_FAILED = "LEADS/GET_RESTRICTED_LEADS_FAILED";

export const GET_EMAIL_TEMPLATES = "LEADS/GET_EMAIL_TEMPLATES";
export const GET_EMAIL_TEMPLATES_COMPLETED =
  "LEADS/GET_EMAIL_TEMPLATES_COMPLETED";
export const GET_EMAIL_TEMPLATES_FAILED = "LEADS/GET_EMAIL_TEMPLATES_FAILED";

export const SEND_CAMPAIGN = "LEADS/SEND_CAMPAIGN";
export const SEND_CAMPAIGN_COMPLETED = "LEADS/SEND_CAMPAIGN_COMPLETED";
export const SEND_CAMPAIGN_FAILED = "LEADS/SEND_CAMPAIGN_FAILED";
