import { useState } from 'react';

export default function usePagination(defaultLimit, defaultPage) {
  const [page, setPage] = useState(defaultPage || 0);
  const [limit, setLimit] = useState(defaultLimit || 20);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = event => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  return { page, limit, handleChangePage, handleChangeLimit };
}
