import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import {
  List,
  IconButton,
  ListItemIcon,
  ListItemText
} from "@material-ui/core/";
import {
  Menu,
  Dashboard,
  Person,
  PhotoLibrary,
  ChevronLeft,
  Group,
  Web
} from "@material-ui/icons";
import { variables } from "helpers";
import { AppDrawer, DrawerToolbar, LinkItem } from "./DrawerStyle";
import { Divider, Typography } from "views/ui";

const primaryMenu = [
  {
    label: "Dashboard",
    link: "/dashboard",
    icon: <Dashboard />,
    role: [variables.userRoles.admin, variables.userRoles.staff]
  },
  {
    label: "Orocube",
    link: "/sites/orocube",
    icon: <Web />,
    role: [variables.userRoles.admin]
  }
  // {
  //   label: "User Signup",
  //   link: "/signup",
  //   icon: <AccountBox />,
  //   role: [variables.userRoles.admin]
  // }
];

const secondaryMenu = [
  // {
  //   label: "Users",
  //   link: "/users",
  //   icon: <SupervisorAccount />,
  //   role: [variables.userRoles.admin]
  // },
  {
    label: "Leads",
    link: "/leads",
    icon: <Person />,
    role: [variables.userRoles.admin]
  },
  {
    label: "Leads By Summary",
    link: "/leads-by-group",
    icon: <Group />,
    role: [variables.userRoles.admin]
  },
  {
    label: "Galleries V2",
    link: "/galleries-v2",
    icon: <PhotoLibrary />,
    role: [variables.userRoles.admin, variables.userRoles.staff]
  },
  {
    label: "Galleries",
    link: "/galleries",
    icon: <PhotoLibrary />,
    role: [variables.userRoles.admin, variables.userRoles.staff]
  }
];

const AdminDrawer = ({
  open,
  role,
  drawerOpenWidth,
  drawerCloseWidth,
  handleToggleChange
}) => {
  return (
    <AppDrawer
      drawerOpenWidth={drawerOpenWidth}
      drawerCloseWidth={drawerCloseWidth}
      variant="permanent"
      open={open}
    >
      <DrawerToolbar>
        {open ? (
          <>
            <Typography color="secondary" variant="h6">
              Oro Backoffice
            </Typography>
            <IconButton onClick={handleToggleChange}>
              <ChevronLeft color="secondary" />
            </IconButton>
          </>
        ) : (
          <IconButton
            color="secondary"
            aria-label="open drawer"
            onClick={handleToggleChange}
            edge="start"
          >
            <Menu />
          </IconButton>
        )}
      </DrawerToolbar>
      <Divider light />
      <List>
        {primaryMenu.map((menu, index) => (
          <Fragment key={index}>
            {menu.role.includes(role) && (
              <Link to={menu.link}>
                <LinkItem button>
                  <ListItemIcon>{menu.icon}</ListItemIcon>
                  <ListItemText primary={menu.label} />
                </LinkItem>
              </Link>
            )}
          </Fragment>
        ))}
      </List>
      <Divider color="grey" />
      <List>
        {secondaryMenu.map((menu, index) => (
          <Fragment key={index}>
            {menu.role.includes(role) && (
              <Link to={menu.link} key={index}>
                <LinkItem button>
                  <ListItemIcon>{menu.icon}</ListItemIcon>
                  <ListItemText primary={menu.label} />
                </LinkItem>
              </Link>
            )}
          </Fragment>
        ))}
      </List>
    </AppDrawer>
  );
};

export default AdminDrawer;
