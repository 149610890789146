import React from "react";

import withSVG from "./withSVG";

const MailIcon = () => (
  <g>
    <path d="M158.526 176.135c.475 25.361 10.737 49.25 28.897 67.265 18.159 18.014 42.129 28.087 67.495 28.365 11.825.131 23.461-1.844 34.484-5.866 3.892-1.418 5.896-5.723 4.478-9.614s-5.723-5.898-9.614-4.478a81.915 81.915 0 01-29.184 4.958c-44.146-.483-80.733-36.78-81.561-80.971-.599-22.455 8.518-44.538 25.01-60.587 16.498-16.053 38.831-24.555 61.324-23.35 42.998 1.948 77.534 37.205 78.625 80.266.255 10.038-1.268 19.879-4.524 29.247-2.163 6.221-8.097 10.4-14.768 10.4h-.002c-8.645 0-15.678-7.033-15.678-15.679V134.27c0-4.143-3.357-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v5.406c-8.504-7.994-19.936-12.906-32.5-12.906-26.191 0-47.5 21.309-47.5 47.5s21.309 47.5 47.5 47.5c13.563 0 25.807-5.724 34.471-14.871 4.381 11.599 15.593 19.872 28.706 19.872h.003c13.05 0 24.679-8.229 28.936-20.475 3.853-11.079 5.652-22.704 5.353-34.554-1.29-50.895-42.115-92.566-92.877-94.866-26.598-1.438-53.039 8.615-72.528 27.581-19.486 18.96-30.254 45.108-29.546 71.678zm97.483 30.635c-17.921 0-32.5-14.579-32.5-32.5s14.579-32.5 32.5-32.5 32.5 14.579 32.5 32.5-14.579 32.5-32.5 32.5z" />
    <path d="M509.119 199.12L298.839 34.812c-24.322-19.003-58.375-19.104-82.809-.242L2.936 199.08a7.567 7.567 0 00-2.935 5.95v66c0 4.143 3.357 7.5 7.5 7.5s7.5-3.357 7.5-7.5v-53.22l188.602 105.354L15.001 468.765V306.03c0-4.143-3.357-7.5-7.5-7.5s-7.5 3.357-7.5 7.5v178c-.066 4.026 3.471 7.57 7.5 7.5h497c4.001.054 7.584-3.477 7.5-7.5V205.916c.18-2.574-.803-5.206-2.882-6.796zM29.489 476.53l195.708-151.087c19.004-14.67 45.489-14.594 64.406.189l3.612 2.822 94.03 73.472a7.5 7.5 0 009.236-11.82l-85.229-66.595 46.113-25.979a7.5 7.5 0 00-7.363-13.069l-51.562 29.049c-24.131-18.571-57.647-18.694-81.911-.31l-195.45-109.18 204.119-157.58c19.004-14.67 45.489-14.593 64.406.189l202.162 157.964-112.14 63.177a7.5 7.5 0 00-2.853 10.216c2.045 3.643 6.673 4.866 10.216 2.853l110.013-61.979V468.65l-73.729-57.61a7.5 7.5 0 00-9.236 11.82l68.685 53.669H29.489z" />
  </g>
);

export default withSVG(MailIcon, { viewBox: "0 0 512 512", size: 16 });
