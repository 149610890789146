import React, { useEffect, useState } from "react";
import { Fab, Grid } from "@material-ui/core";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Wrapper,
  TextField,
  Drawer,
  Select,
  RichEditor,
  Dialog
} from "views/ui";
import { SiteSectionFormMenu, SubSection, SubSectionForm } from "./";
import { useAnchor, useToggle } from "hooks";
import { featureActions } from "store/features";
import { Form, withFormik } from "formik";
import { textFormatter } from "utils";
import uuid from "react-uuid";

const SiteSectionFormWrapper = styled(Form)`
  position: relative;
`;

const AddItem = styled(Fab)`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: ${({ theme }) => theme.zIndex.modal};
`;

const ButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  background: #fff;
  z-index: ${({ theme }) => theme.zIndex.drawer};
  box-shadow: ${({ theme }) => theme.shadows[2]};
  padding-left: 20px;
`;

const prepareOptions = (collections = []) => {
  return collections.map(collection => ({
    label: collection.title,
    value: collection._id
  }));
};

const SiteSectionForm = ({
  open,
  handleCloseSiteSectionForm,
  loading,
  values,
  setFieldValue,
  handleChange,
  section = {}
}) => {
  const dispatch = useDispatch();
  const options = useSelector(({ features }) => ({
    features: features.features,
    benefits: features.features
  }));
  const [anchorEl, setAnchorEl] = useAnchor();
  const [openDialog, setOpenDialog] = useToggle(false);
  const [collections, setCollections] = useState([]);
  const [collectionItems, setCollectionItems] = useState({});
  const [subSection, setSubSection] = useState({});

  useEffect(() => {
    /*
      Set Collection array from options like : [feature, benefits]
      Set Collection Items object from options like : {feature: []}
    */
    const collections = [];
    const collectionItems = {};

    for (const item in options) {
      collectionItems[item] = prepareOptions(options[item]);
      collections.push({
        label: textFormatter.capitalize(item),
        value: item
      });
    }

    setCollectionItems(collectionItems);
    setCollections(collections);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.features.length]);

  useEffect(() => {
    dispatch(featureActions.getFeatures());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleHtml = markup => {
    setFieldValue("description", markup);
  };

  const handleSelectMenuChange = (option, index) => {
    setFieldValue(`collections[${index}]`, { title: option.value, ids: [] });
  };

  const handleSubSectionUpdate = subSectionValue => {
    if (subSectionValue.id) {
      const subSectionIndex = values.subSections.findIndex(
        subSection => subSection.id === subSectionValue.id
      );
      setFieldValue(`subSections[${subSectionIndex}]`, subSectionValue);
    } else {
      const index =
        values.subSections.length > 0 ? values.subSections.length : 0;
      setFieldValue(`subSections[${index}]`, {
        ...subSectionValue,
        id: uuid()
      });
    }

    setOpenDialog(false);
  };

  const handleSubSectionDelete = subSectionId => {
    const subSections = values.subSections.filter(
      subSection => subSection.id !== subSectionId
    );

    setFieldValue(`subSections`, subSections);
  };

  const handleSubSectionClose = () => {
    setSubSection({});
    setOpenDialog(false);
  };

  const handleSubSectionEdit = subSection => {
    setSubSection(subSection);
    setOpenDialog(true);
  };

  const handleCollections = (options, index) => {
    const ids = options.map(option => option.value);

    const items = {
      title: values.collections[index].title,
      ids,
      options: options
    };
    setFieldValue(`collections[${index}]`, items);
  };

  const handleMenu = item => {
    switch (item) {
      case "subtitle":
        const updatedSubtitles = values.subtitles.concat("");
        setFieldValue("subtitles", updatedSubtitles);
        break;
      case "description":
        setFieldValue("description", "Write something here..");
        break;
      case "link":
        const updatedLinks = values.links.concat({
          label: "",
          url: ""
        });
        setFieldValue("links", updatedLinks);
        break;
      case "collections":
        const updatedCollections = values.collections.concat({
          title: "",
          ids: []
        });
        setFieldValue("collections", updatedCollections);
        break;
      case "subSections":
        setOpenDialog(true);
        break;
      default:
        break;
    }
    setAnchorEl();
  };

  const RenderCollectionItemsSelect = ({ index, collectionTitle }) => {
    return (
      <Select
        options={collectionItems[collectionTitle]}
        name={`collections[${index}].ids`}
        isMulti
        value={
          values.collections[index].options ||
          prepareOptions(values.collections[index].collectionItems)
        }
        onChange={options => handleCollections(options, index)}
      />
    );
  };

  return (
    <Drawer open={open} anchor="right" onClose={handleCloseSiteSectionForm}>
      <SiteSectionFormWrapper>
        <Wrapper p={4} height="100vh">
          <TextField
            mb={2}
            label="Title"
            margin="dense"
            variant="outlined"
            fullWidth
            name="title"
            value={values.title}
            rows={4}
            onChange={handleChange}
          />

          {values.subtitles.map((subtitle, index) => (
            <TextField
              key={index}
              mb={2}
              label="Subtitle"
              margin="dense"
              variant="outlined"
              fullWidth
              name={`subtitles[${index}]`}
              value={subtitle}
              rows={4}
              onChange={handleChange}
            />
          ))}

          {values.description && (
            <Wrapper mb={2} border={1} borderColor="grey.400" borderRadius={4}>
              <RichEditor
                name="content"
                html={values.description}
                handleHtml={handleHtml}
              />
            </Wrapper>
          )}

          {values.links.map((link, index) => (
            <Wrapper display="flex" key={index} name={`links[${index}]`} mb={2}>
              <TextField
                label="Link Label"
                margin="dense"
                variant="outlined"
                width="200"
                name={`links[${index}].label`}
                value={link.label}
                rows={4}
                mr={1}
                onChange={handleChange}
              />
              <TextField
                label="Url"
                margin="dense"
                variant="outlined"
                fullWidth
                name={`links[${index}].url`}
                value={link.url}
                rows={4}
                onChange={handleChange}
              />
            </Wrapper>
          ))}

          {values.collections.map((collection, index) => (
            <Wrapper display="flex" key={index} mb={2}>
              <Select
                width="200px"
                options={collections}
                mr={1}
                defaultValue={{
                  label: textFormatter.capitalize(collection.title),
                  value: collection.title
                }}
                name={`collections[${index}].title`}
                onChange={option => handleSelectMenuChange(option, index)}
              />

              <RenderCollectionItemsSelect
                index={index}
                collectionTitle={collection.title}
                collectionIds={collection.ids}
              />
            </Wrapper>
          ))}
          <Wrapper mt={4} mb="150px">
            <Grid container spacing={2}>
              {values.subSections.map((subSection, index) => (
                <Grid item xs={6} key={index}>
                  <SubSection
                    subSection={subSection}
                    handleSubSectionEdit={handleSubSectionEdit}
                    handleSubSectionDelete={handleSubSectionDelete}
                  />
                </Grid>
              ))}
            </Grid>
          </Wrapper>
        </Wrapper>
        <ButtonWrapper>
          <Button
            variant="outlined"
            color="primary"
            mt={2}
            mr={1}
            onClick={handleCloseSiteSectionForm}
          >
            Close
          </Button>

          <Button
            // loading={loading["siteForm"]}
            // disabled={loading["siteForm"]}
            variant="contained"
            color="primary"
            mt={2}
            type="submit"
          >
            {section.id ? "Update" : "Add"}
          </Button>
        </ButtonWrapper>

        <AddItem color="primary" aria-label="add" onClick={setAnchorEl}>
          Add
        </AddItem>
      </SiteSectionFormWrapper>

      <Dialog
        open={openDialog}
        label="sub-section"
        heading="Sub Section"
        handleClose={handleSubSectionClose}
      >
        <SubSectionForm
          subSection={subSection}
          handleSubSection={handleSubSectionUpdate}
        />
      </Dialog>

      <SiteSectionFormMenu anchorEl={anchorEl} handleMenu={handleMenu} />
    </Drawer>
  );
};

export default withFormik({
  mapPropsToValues: ({ section = {} }) => {
    const subtitles = section.subtitles || [];
    const title = section.title || "";
    const description = section.description || "";
    const links = section.links || [];
    const collections = section.collections || [];
    const subSections = section.subSections || [];

    return {
      title,
      subtitles,
      description,
      links,
      collections,
      subSections
    };
  },

  handleSubmit: (values, { props: { handleSectionUpdate } }) => {
    handleSectionUpdate(values);
  }
})(SiteSectionForm);
