import React from "react";
import { Wrapper } from "views/ui";
import ImageList from "views/components/ImageDetails/ImageList";

export default function GalleriesPage() {
  return (
    <Wrapper>
      <ImageList />
    </Wrapper>
  );
}
