import React, { useState, useEffect } from "react";
import logo from "../../../assets/images/logo.png";
import { Wrapper, Image, Typography, Paper, Button, Dialog } from "views/ui";
import { siteActions } from "store/sites";
import { jenkinsActions } from "store/jenkins";
import { SiteInfoForm } from "./";
import { useToggle } from "hooks";
import { useDispatch } from "react-redux";

const SiteInfo = ({ siteId, metaData = {} }) => {
  const dispatch = useDispatch();
  const { updateSite } = siteActions;
  const [openDialog, setOpenDialog] = useToggle(false);
  const [siteBuildInProgress, setSiteBuildInProgress] = useState(null);
  const { buildSite } = jenkinsActions;

  const handleSiteInfo = async values => {
    await dispatch(
      updateSite(siteId, {
        metaData: values
      })
    );
    setOpenDialog(false);
  };

  useEffect(() => {
    if (siteBuildInProgress) {
      setTimeout(() => {
        setSiteBuildInProgress(false);
      }, 60000); // 1 minutes
    }
  }, [siteBuildInProgress]);

  const handleBuildSite = () => {
    dispatch(
      buildSite({
        site: "orocube"
      })
    );

    setSiteBuildInProgress(true);
  };

  return (
    <Paper p={3}>
      <Image center src={logo} alt="Orocube.com" mb={4} />
      <Typography variant="h3" mb={1}>
        {metaData.title}
      </Typography>
      {metaData.subtitles &&
        metaData.subtitles.map((subtitle, index) => (
          <Typography variant="body1" mb={2} key={index}>
            {subtitle}
          </Typography>
        ))}
      {metaData.phones &&
        metaData.phones.map((phone, index) => (
          <Typography variant="body1" mb={2} key={index}>
            {phone.label}: {phone.value}
          </Typography>
        ))}
      {metaData.emails &&
        metaData.emails.map((email, index) => (
          <Typography variant="body1" mb={2} key={index}>
            {email.label}: {email.value}
          </Typography>
        ))}

      <Typography variant="subtitle1" mb={3}>
        {metaData.copyright}
      </Typography>
      <Wrapper display="flex">
        <Button
          color="primary"
          mr={1}
          variant="outlined"
          size="small"
          onClick={setOpenDialog}
        >
          Edit Info
        </Button>
        <Button
          href={metaData.siteUrl}
          mr={1}
          color="primary"
          variant="outlined"
          size="small"
          target="_blank"
        >
          Visit Site
        </Button>
        <Button
          color="primary"
          variant="contained"
          size="small"
          disabled={siteBuildInProgress}
          loading={siteBuildInProgress}
          onClick={handleBuildSite}
        >
          Build Site
        </Button>
      </Wrapper>

      <Dialog
        open={openDialog}
        label="sub-section"
        heading="Sub Section"
        handleClose={setOpenDialog}
      >
        <SiteInfoForm siteInfo={metaData} handleSiteInfo={handleSiteInfo} />
      </Dialog>
    </Paper>
  );
};

export default SiteInfo;
