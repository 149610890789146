import React from 'react';
import { Wrapper } from 'views/ui';
import ImageDetailsContainer from 'views/components/ImageDetails';

export default function GalleriesPage() {
  return (
    <Wrapper>
      <ImageDetailsContainer />
    </Wrapper>
  );
}
