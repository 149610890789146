import React, { useEffect, useState } from "react";
import { Dialog, Dropzone } from "../../../views/ui";
import { galleryActions } from "store/gallery";
import { useSelector, useDispatch } from "react-redux";
import { objectToFormData } from "../../../utils";

const UploadImage = ({ open, handleClose }) => {
  const { getImageTags, uploadImage } = galleryActions;
  const dispatch = useDispatch();
  const imageTags = useSelector(({ gallery }) => gallery.imageTags);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getImageTags());
  }, [dispatch, getImageTags]);

  const handleUpload = async values => {
    setLoading(true);
    const formData = objectToFormData(values);

    await dispatch(uploadImage(formData));
    await dispatch(getImageTags());
    setLoading(false);
    handleClose();
  };

  return (
    <Dialog open={open} handleClose={handleClose} handleUpload={handleUpload}>
      <Dropzone
        imageTags={imageTags}
        handleUpload={handleUpload}
        loading={loading}
      />
    </Dialog>
  );
};

export default UploadImage;
