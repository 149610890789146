import React from 'react';
import { Wrapper } from 'views/ui';
import { BlogEdit } from 'views/components/Blog';

export default function LeadsPage() {
  return (
    <Wrapper p={2}>
      <BlogEdit />
    </Wrapper>
  );
}
