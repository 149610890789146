import {
  subDays,
  subWeeks,
  subMonths,
  subYears,
  getDate,
  getYear,
  getMonth,
} from 'date-fns';

export const rangeOptions = [
  { label: 'Last Week', value: 'lastWeek' },
  { label: 'Last Month', value: 'lastMonth' },
  { label: 'Last Year', value: 'lastYear' },
];

export function calculateDates(value) {
  let startDate = new Date();
  let endDate = new Date();
  const yesterday = subDays(endDate, 1);

  switch (value) {
    case 'lastWeek':
      endDate = yesterday;
      startDate = subWeeks(endDate, 1);
      break;
    case 'lastMonth':
      endDate = yesterday;
      startDate = subMonths(endDate, 1);
      break;
    case 'lastYear':
      endDate = yesterday;
      startDate = subYears(endDate, 1);
      break;
    default:
      break;
  }

  return {
    startDate,
    endDate,
  };
}

export function getFormattedDate(date) {
  const dateObj = new Date(date);
  return {
    day: getDate(dateObj),
    month: getMonth(dateObj) + 1,
    year: getYear(dateObj),
  };
}

export function getDateObject(date = {}) {
  const { day, month, year } = date;
  return new Date(year, month - 1, day);
}
