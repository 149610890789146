import * as types from "./types";

export const getSites = () => {
  return {
    type: types.GET_SITES,
    payload: {
      path: `sites`,
      method: "GET"
    },
    meta: {
      API: true,
      jwt: true,
      label: "getSites",
      loadMore: false
    }
  };
};

export const getSite = slug => {
  return {
    type: types.GET_SITE,
    payload: {
      path: `sites/${slug}`,
      method: "GET"
    },
    meta: {
      API: true,
      jwt: true,
      label: "getSite",
      loadMore: false
    }
  };
};

export const updateSite = (id, data) => {
  return {
    type: types.UPDATE_SITE,
    payload: {
      path: `sites/${id}`,
      method: "PUT",
      data
    },
    meta: {
      API: true,
      jwt: true,
      label: "updateSite",
      loadMore: false
    }
  };
};

export const createSite = data => {
  return {
    type: types.CREATE_SITE,
    payload: {
      path: `sites`,
      method: "POST",
      data
    },
    meta: {
      API: true,
      jwt: true,
      label: "createSite",
      loadMore: false
    }
  };
};
