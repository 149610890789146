import produce from "immer";
import * as types from "./types";

const initialState = {
  blogs: {
    list: [],
    total: 0
  },
  blog: {}
};

const blogReducers = function (state = initialState, action) {
  const { type, payload } = action;

  return produce(state, draft => {
    switch (type) {
      case types.GET_BLOGS_COMPLETED:
        draft.blogs = payload;
        break;
      case types.GET_BLOG_COMPLETED:
        draft.blog = payload;
        break;
      case types.CREATE_BLOG_COMPLETED:
        draft.blog = payload;
        break;
      case types.UPDATE_BLOG_COMPLETED:
        draft.blog = payload;
        break;
      case types.DELETE_BLOG_COMPLETED:
        const updatedBlogs = state.blogs.list.filter(
          blog => blog.id !== payload.id
        );
        draft.blogs.list = updatedBlogs;
        draft.blogs.total = draft.blogs.total - 1;
        break;
      case types.EMPTY_BLOG_DATA:
        draft.blog = {};
        break;
      default:
        return state;
    }
  });
};

export default blogReducers;
