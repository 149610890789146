import React, { useEffect, useState, Fragment } from "react";
import { Grid, AppBar, Tab, Tabs } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Wrapper } from "views/ui";
import { siteActions } from "store/sites";
import { SiteSection, SiteSectionForm, SiteInfo, SitePage, SiteNav } from "./";
import { useToggle } from "hooks";
import uuid from "react-uuid";

const SitesContainer = () => {
  const { slug } = useParams();
  const site = useSelector(({ sites }) => sites.site);
  const dispatch = useDispatch();
  const { getSite, updateSite } = siteActions;
  const [open, handleToggleChange] = useToggle(false);
  const [section, setSection] = useState({});
  const [sectionTabValue, setSectionTabValue] = useState(0);
  const [pageTabValue, setPageTabValue] = useState(0);

  useEffect(() => {
    dispatch(getSite(slug));
  }, [dispatch, getSite, slug]);

  const handleSectionUpdate = values => {
    let data;
    const updatedPages = [...site.pages];
    const currentPageIndex = updatedPages.findIndex(
      (_, index) => index === pageTabValue
    );

    if (section.id) {
      const updatedSection = [...updatedPages[currentPageIndex].sections];
      const sectionIndex = updatedSection.findIndex(
        item => item.id === section.id
      );
      updatedSection[sectionIndex] = {
        ...updatedSection[sectionIndex],
        ...values
      };
      const updatedCurrentPage = {
        ...updatedPages[currentPageIndex],
        sections: updatedSection
      };
      updatedPages[currentPageIndex] = updatedCurrentPage;

      data = {
        pages: updatedPages
      };
    } else {
      const updatedCurrentPage = {
        ...updatedPages[currentPageIndex],
        sections: updatedPages[currentPageIndex].sections.concat({
          id: uuid(),
          ...values
        })
      };
      updatedPages[currentPageIndex] = updatedCurrentPage;

      data = {
        pages: updatedPages
      };
    }

    dispatch(updateSite(site._id, data));
    handleToggleChange();
  };

  const handleSiteSectionDelete = sectionId => {
    const updatedPages = [...site.pages];
    const currentPageIndex = updatedPages.findIndex(
      (_, index) => index === pageTabValue
    );

    let updatedSections = [...updatedPages[currentPageIndex].sections];

    updatedSections = updatedSections.filter(
      section => section.id !== sectionId
    );

    const updatedCurrentPage = {
      ...updatedPages[currentPageIndex],
      sections: updatedSections
    };

    updatedPages[currentPageIndex] = updatedCurrentPage;

    const data = {
      pages: updatedPages
    };

    dispatch(updateSite(site._id, data));
  };

  const handleSiteNav = ({ links }) => {
    const data = {
      menus: [links]
    };
    dispatch(updateSite(site._id, data));
  };

  const handleSitePage = ({ pages }) => {
    const data = {
      pages
    };
    dispatch(updateSite(site._id, data));
  };

  const handleSiteSectionEdit = index => {
    setSection(site.pages[pageTabValue].sections[index]);
    handleToggleChange();
  };

  const handleSiteSectionCreate = () => {
    setSection({});
    handleToggleChange();
  };

  const handleCloseSiteSectionForm = () => {
    setSection({});
    handleToggleChange();
  };

  return (
    <>
      <Wrapper mb={4}>
        <Grid container spacing={4}>
          <Grid item md={3}>
            <SiteInfo
              metaData={site && site.metaData}
              siteId={site && site._id}
            />
          </Grid>
          <Grid item md={5}>
            {site.menus && site.menus[0] && (
              <SiteNav links={site.menus[0]} handleSiteNav={handleSiteNav} />
            )}
          </Grid>
          <Grid item md={4}>
            {site.menus && site.menus[0] && (
              <SitePage pages={site.pages} handleSitePage={handleSitePage} />
            )}
          </Grid>
        </Grid>
      </Wrapper>
      <Grid container spacing={4}>
        <Grid item md={2}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={pageTabValue}
            onChange={(_, newValue) => setPageTabValue(newValue)}
            aria-label="Vertical tabs example"
          >
            {site.pages &&
              site.pages.map(page => <Tab label={page.label} key={page._id} />)}
          </Tabs>
        </Grid>
        <Grid item md={10}>
          {site.pages &&
            site.pages.map((page, pageIndex) => (
              <Fragment key={pageIndex}>
                {pageTabValue === pageIndex && (
                  <AppBar position="static">
                    <Tabs
                      value={sectionTabValue}
                      onChange={(_, newValue) => setSectionTabValue(newValue)}
                      aria-label="horizontal tab"
                    >
                      {page.sections.map((_, index) => (
                        <Tab label={`Section ${index + 1}`} key={index} />
                      ))}
                    </Tabs>
                  </AppBar>
                )}
                {page.sections.length
                  ? page.sections.map((section, index) => (
                      <Wrapper key={index}>
                        {sectionTabValue === index &&
                          pageTabValue === pageIndex && (
                            <SiteSection
                              index={index}
                              section={section}
                              handleSectionUpdate={handleSectionUpdate}
                              handleSiteSectionEdit={handleSiteSectionEdit}
                              handleSiteSectionCreate={handleSiteSectionCreate}
                              handleSiteSectionDelete={handleSiteSectionDelete}
                            />
                          )}
                      </Wrapper>
                    ))
                  : pageTabValue === pageIndex && (
                      <Wrapper
                        width="100%"
                        height="200px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleSiteSectionCreate}
                        >
                          Create First Section
                        </Button>
                      </Wrapper>
                    )}
              </Fragment>
            ))}
        </Grid>
      </Grid>

      {open && (
        <SiteSectionForm
          open={open}
          section={section}
          handleCloseSiteSectionForm={handleCloseSiteSectionForm}
          handleSectionUpdate={handleSectionUpdate}
        />
      )}
    </>
  );
};

export default SitesContainer;
