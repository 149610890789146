import React, { useEffect, useState, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CSVLink } from "react-csv";
import format from "date-fns/format";
import { leadActions } from "store/leads";
import { supplementActions } from "store/supplements";
import { usePagination, useToggle } from "hooks";
import SendEmail from "./SendEmail";
import LeadGroups, { groups } from "./LeadGroups";
import LeadsTable from "./Leads";
import Filter from "views/ui/composed/Filter";
import {
  Paper,
  PreLoader,
  Dialog,
  Switch,
  Typography,
  Wrapper,
  Button,
  TextField
} from "views/ui";

const prepareOptions = (data, label) => {
  return data.map(data => {
    return {
      label: data[label],
      value: data.id
    };
  });
};

const products = [
  { value: "oropos", label: "Oropos" },
  { value: "floreant", label: "Floreant" },
  { value: "both", label: "Both" }
];

const initialBlockState = {
  IPAddress: false,
  email: false,
  phone: false
};

const LeadsContainer = () => {
  const dispatch = useDispatch();
  const btnRef = useRef(null);
  const leads = useSelector(({ leads }) => leads.leads);
  const leadsForExport = useSelector(({ leads }) => leads.leadsForExport);
  const countries = useSelector(({ supplements }) => supplements.countries);
  const loading = useSelector(({ ui }) => ui.loading);
  const {
    getLeads,
    getLeadsForExport,
    restrictLeads,
    getRestrictLead,
    deleteRestrictLeads,
    updateLeads,
    getEmailTemplates,
    sendCampaign,
    updateGroups
  } = leadActions;
  const { getCountries } = supplementActions;
  const { page, limit, handleChangePage, handleChangeLimit } =
    usePagination(100);
  const [filters, setFilters] = useState({});
  const [openBlockDialog, setOpenBlockDialog] = useToggle(false);
  const [openEmailDialog, setOpenEmailDialog] = useToggle(false);
  const [isBanningLead, setIsBanningLead] = useState(false);
  const [isEmailSentDialogOpen, setIsEmailSentDialogOpen] = useState(false);
  const [isLeadGroupDialogOpen, setIsLeadGroupDialogOpen] = useState(false);
  const [blockFormData, setBlockFormData] = useState(initialBlockState);
  const [lead, setLead] = useState({});
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [pageIndex, setPageIndex] = useState(0);
  const [group, setGroup] = useState(null);

  useEffect(() => {
    dispatch(
      getLeads({
        page: pageIndex,
        limit: pageSize,
        ...filters
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, pageIndex]);

  useEffect(() => {
    setPageCount(Math.ceil(leads.total / pageSize));
  }, [leads, pageSize]);

  useEffect(() => {
    dispatch(getCountries());
  }, [dispatch, getCountries]);

  const handleFilter = values => {
    const filters = {
      startDate: values.dates.startDate && values.dates.startDate.toString(),
      endDate: values.dates.endDate && values.dates.endDate.toString(),
      status: values.status.value,
      product: values.product.value,
      country: values.country.value,
      IPAddress: values.IPAddress,
      email: values.email,
      fullName: values.fullName,
      isTest: values.isTest,
      isBanned: values.isBanned,
      mailInteraction: values.mailInteraction.value,
      mailType: values.mailType.value,
      group: values.groups.value
    };
    setFilters(filters);

    if (page === 0) {
      dispatch(getLeads({ ...filters, limit }));
    } else {
      handleChangePage("", 0);
    }
  };

  const handleExport = async () => {
    await dispatch(getLeadsForExport({ ...filters, page: 0 }));
    btnRef.current.click();
  };

  const handleCloseBlockLeadDialog = () => {
    setOpenBlockDialog(false);
    setLead({});
    setBlockFormData(initialBlockState);
    setIsBanningLead(false);
  };

  const handleOpenBlockLeadDialog = async lead => {
    const blockData = {
      ...initialBlockState
    };
    setIsBanningLead(false);
    setOpenBlockDialog(true);
    // if (selectedLeads.length === 1) {
    //   const { data = [] } = await dispatch(getRestrictLead(selectedLeads[0]));
    //   const restrictedLeads = {
    //     ...lead,
    //     restrictedItems: data.map(item => item.type)
    //   };

    //   data.forEach(item => {
    //     if (item.type in blockData) {
    //       blockData[item.type] = true;
    //     }
    //   });

    //   setBlockFormData(blockData);
    // }
  };

  const handleBlockForm = (label, value) => {
    setBlockFormData({ ...blockFormData, [label]: value });
  };

  const handleBlockLead = async () => {
    setIsBanningLead(true);
    const keys = Object.keys(blockFormData);
    for (let i = 0; i < keys.length; i++) {
      if (
        blockFormData[keys[i]] &&
        !lead.restrictedItems.includes(keys[i]) &&
        lead[keys[i]]
      ) {
        await dispatch(
          restrictLeads({
            type: keys[i],
            value: lead[keys[i]],
            leadId: selectedLeads[0]
          })
        );
      }

      if (!blockFormData[keys[i]] && lead.restrictedItems.includes(keys[i])) {
        await dispatch(
          deleteRestrictLeads(lead.id, {
            type: keys[i]
          })
        );
      }
    }

    const isBannedItems = keys.filter(key => blockFormData[key]);

    if (!isBannedItems.length) {
      await dispatch(
        updateLeads(lead.id, {
          isBanned: false
        })
      );
    } else if (!lead.isBanned) {
      await dispatch(
        updateLeads(lead.id, {
          isBanned: true
        })
      );
    }

    setOpenBlockDialog(false);
    setIsBanningLead(false);
  };

  const handleOpenEmailSentDialog = () => {
    setIsEmailSentDialogOpen(true);
  };

  const handleCloseEmailSentDialog = () => {
    setIsEmailSentDialogOpen(false);
  };

  const handlePagination = ({
    pageSize: changedPageSize,
    pageIndex: changedPageIndex
  }) => {
    if (changedPageSize !== pageSize) {
      setPageSize(changedPageSize);
    }

    if (changedPageIndex !== pageIndex) {
      setPageIndex(changedPageIndex);
    }
  };

  const handleRowSelection = useCallback(lead => {
    setSelectedLeads(lead.map(lead => lead.id));
  }, []);

  const handleSendEmail = values => {
    handleCloseEmailSentDialog();

    dispatch(
      sendCampaign({
        ...values,
        leadIds: selectedLeads
      })
    );
  };

  const handleGroupChange = event => {
    setGroup(event.target.value);
  };

  const handleGroupSubmit = () => {
    dispatch(
      updateGroups({
        group,
        leadIds: selectedLeads
      })
    );

    setIsLeadGroupDialogOpen(false);
  };

  return (
    <>
      <CSVLink
        data={leadsForExport}
        filename={`leads-${format(new Date(), "yyyy/MM/dd")}.csv`}
      >
        <button style={{ display: "none" }} ref={btnRef} />
      </CSVLink>
      <Paper mb={4} py={1} px={2}>
        <Filter
          handleFilter={handleFilter}
          config={{
            type: "default",
            datePicker: "range",
            textField: [
              {
                placeholder: "Name",
                name: "fullName"
              },
              {
                placeholder: "Email",
                name: "email"
              },
              {
                placeholder: "IP Address",
                name: "IPAddress"
              }
            ],
            select: [
              {
                placeholder: "Verify By",
                name: "status",
                options: [
                  {
                    label: "Phone",
                    value: "byPhone"
                  },
                  {
                    label: "Email",
                    value: "byEmail"
                  },
                  {
                    label: "Both",
                    value: "both"
                  },
                  {
                    label: "Unverified",
                    value: "unverified"
                  }
                ]
              },
              {
                placeholder: "Product",
                name: "product",
                options: products
              },
              {
                placeholder: "Country",
                name: "country",
                options: prepareOptions(countries, "name")
              },
              {
                placeholder: "Mail Type",
                name: "mailType",
                options: [
                  {
                    label: "Event",
                    value: "event"
                  },
                  {
                    label: "Scheduled",
                    value: "scheduled"
                  }
                ]
              },
              {
                placeholder: "Mail Status",
                name: "mailInteraction",
                options: [
                  {
                    label: "Open",
                    value: "open"
                  },
                  {
                    label: "Bounce",
                    value: "bounce"
                  },
                  {
                    label: "Spam",
                    value: "spam"
                  },
                  {
                    label: "Unsub",
                    value: "unsub"
                  }
                ]
              },
              {
                placeholder: "Groups",
                name: "groups",
                options: groups
              }
            ],
            switchField: [
              {
                label: "Show Test",
                name: "isTest"
              },
              {
                label: "Show Banned",
                name: "isBanned"
              }
            ]
          }}
        />
      </Paper>
      {loading.leads ? (
        <PreLoader />
      ) : (
        <LeadsTable
          leads={leads}
          page={page}
          limit={limit}
          isLeadsSelected={selectedLeads.length}
          handleChangePage={handleChangePage}
          handleChangeLimit={handleChangeLimit}
          handleExport={handleExport}
          handleOpenBlockLeadDialog={handleOpenBlockLeadDialog}
          handleOpenEmailSentDialog={handleOpenEmailSentDialog}
          exportBtnLoading={loading.leadsForExport}
          handlePagination={handlePagination}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageSize={pageSize}
          handleRowSelection={handleRowSelection}
          handleLeadGroupDialog={() =>
            setIsLeadGroupDialogOpen(prevState => !prevState)
          }
        />
      )}

      <Dialog
        maxWidth="sm"
        open={openBlockDialog}
        handleClose={handleCloseBlockLeadDialog}
      >
        {selectedLeads.length > 1 ? (
          <Typography variant="h4" mb={2}>
            Please Select only one leads
          </Typography>
        ) : (
          <>
            <Typography variant="h4" mb={2}>
              Block by:
            </Typography>
            {loading.getRestrictLead ? (
              <PreLoader />
            ) : (
              <Wrapper display="flex" justifyContent="space-between" pb={2}>
                <Switch
                  name="IPAddress"
                  label="IP Address"
                  value={blockFormData.IPAddress}
                  onChange={handleBlockForm}
                />
                <Switch
                  name="email"
                  label="Email"
                  value={blockFormData.email}
                  onChange={handleBlockForm}
                />
                <Switch
                  name="phone"
                  label="Phone"
                  value={blockFormData.phone}
                  onChange={handleBlockForm}
                />
              </Wrapper>
            )}

            <Wrapper display="flex" mt={3} justifyContent="flex-end">
              <Button
                size="small"
                onClick={handleBlockLead}
                variant="contained"
                color="primary"
                disabled={isBanningLead}
                loading={isBanningLead}
              >
                {!lead.isBanned ? "Block Lead" : "Update Block Lead"}
              </Button>
            </Wrapper>
          </>
        )}
      </Dialog>

      <Dialog
        maxWidth="sm"
        open={isEmailSentDialogOpen}
        handleClose={handleCloseEmailSentDialog}
      >
        <SendEmail handleSendEmail={handleSendEmail} />
      </Dialog>

      <Dialog
        maxWidth="sm"
        open={isLeadGroupDialogOpen}
        handleClose={() => setIsLeadGroupDialogOpen(false)}
      >
        <LeadGroups
          value={+group}
          handleChange={handleGroupChange}
          handleClose={() => setIsLeadGroupDialogOpen(false)}
          handleSubmit={handleGroupSubmit}
        />
      </Dialog>
    </>
  );
};

export default LeadsContainer;
