import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { leadActions } from "store/leads";
import { supplementActions } from "store/supplements";
import { useToggle } from "hooks";
import LeadsTable from "./LeadsGroup";
import { PreLoader, Dialog, Wrapper, Button, TextField, Paper } from "views/ui";
import Filter from "views/ui/composed/Filter";
import SendEmail from "./SendEmail";

const LeadsByGroup = () => {
  const dispatch = useDispatch();
  const leads = useSelector(({ leads }) => leads.leadsGroupBy);
  const loading = useSelector(({ ui }) => ui.loading);
  const { getLeadsByGroup, sendCampaign } = leadActions;
  const { getCountries } = supplementActions;
  const [openEmailDialog, setOpenEmailDialog] = useToggle(false);
  const [isEmailSentDialogOpen, setIsEmailSentDialogOpen] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [pageIndex, setPageIndex] = useState(0);
  const [filters, setFilters] = useState({});
  const [selectedLeads, setSelectedLeads] = useState([]);

  useEffect(() => {
    dispatch(
      getLeadsByGroup({
        page: pageIndex,
        limit: pageSize
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, pageIndex]);

  useEffect(() => {
    setPageCount(Math.ceil(leads.total / pageSize));
  }, [leads, pageSize]);

  useEffect(() => {
    dispatch(getCountries());
  }, [dispatch, getCountries]);

  const handleOpenEmailDialog = lead => {
    setOpenEmailDialog(true);
  };

  const handleOpenEmailSentDialog = () => {
    setIsEmailSentDialogOpen(true);
  };

  const handleCloseEmailSentDialog = () => {
    setIsEmailSentDialogOpen(false);
  };

  const handleRowSelection = useCallback(lead => {
    setSelectedLeads(lead.map(lead => lead.details[0].id));
  }, []);

  const handleSendEmail = values => {
    handleCloseEmailSentDialog();

    dispatch(
      sendCampaign({
        ...values,
        leadIds: selectedLeads
      })
    );
  };

  const handlePagination = ({
    pageSize: changedPageSize,
    pageIndex: changedPageIndex
  }) => {
    if (changedPageSize !== pageSize) {
      setPageSize(changedPageSize);
    }

    if (changedPageIndex !== pageIndex) {
      setPageIndex(changedPageIndex);
    }
  };

  const handleFilter = values => {
    const filters = {
      startDate: values.dates.startDate && values.dates.startDate.toString(),
      endDate: values.dates.endDate && values.dates.endDate.toString(),
      groupBy: values.groupBy.value || "email"
    };
    setFilters(filters);

    if (pageIndex === 0) {
      dispatch(getLeadsByGroup({ ...filters, limit: pageSize }));
    } else {
      setPageIndex(0);
    }
  };

  return (
    <>
      <Paper mb={4} py={1} px={2}>
        <Filter
          handleFilter={handleFilter}
          config={{
            type: "default",
            datePicker: "range",
            textField: [],
            select: [
              {
                placeholder: "Group By",
                name: "groupBy",
                options: [
                  {
                    label: "Phone",
                    value: "phone"
                  },
                  {
                    label: "Email",
                    value: "email"
                  },
                  {
                    label: "IP Address",
                    value: "IPAddress"
                  }
                ]
              }
            ]
          }}
        />
      </Paper>
      {loading.leads ? (
        <PreLoader />
      ) : (
        <LeadsTable
          leads={leads}
          handleOpenEmailDialog={handleOpenEmailDialog}
          handleOpenEmailSentDialog={handleOpenEmailSentDialog}
          handlePagination={handlePagination}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageSize={pageSize}
          filter={filters.groupBy}
          handleRowSelection={handleRowSelection}
        />
      )}

      <Dialog
        maxWidth="sm"
        open={isEmailSentDialogOpen}
        handleClose={handleCloseEmailSentDialog}
      >
        <SendEmail handleSendEmail={handleSendEmail} />
      </Dialog>
    </>
  );
};

export default LeadsByGroup;
