import React from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  root: {
    position: 'absolute',
    top: '50%',
    left: 0,
    height: 22,
    width: 24,
    clipPath: 'polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    transform: 'translateY(-50%)',
  },
};

const SectionBadge = ({ classes, count, colors }) => {
  return (
    <div
      className={classes.root}
      // colors[count - 1] + 95 = applying opacity
      style={{ background: colors ? colors[count - 1] + 95 : '#bdbdbd' }}
    >
      {count}
    </div>
  );
};

export default withStyles(styles)(SectionBadge);
