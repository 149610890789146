import produce from "immer";
import * as types from "./types";
import { textFormatter } from "utils";

const initialState = {
  leads: {
    list: [],
    total: 0
  },
  leadsGroupBy: {
    list: [],
    total: 0
  },
  emailTemplates: [],
  leadsForExport: []
};

const leadReducers = function (state = initialState, action) {
  const { type, payload } = action;

  return produce(state, draft => {
    switch (type) {
      case types.GET_LEADS_COMPLETED:
        draft.leads = payload;
        break;
      case types.GET_LEADS_BY_GROUP_COMPLETED:
        draft.leadsGroupBy = payload;
        break;
      case types.GET_LEADS_FOR_EXPORT_COMPLETED:
        const list = payload.list.map(lead => {
          lead.BusinessType = lead.businessType
            ? lead.businessType
            : lead.BusinessType && lead.BusinessType.title;
          lead.country = lead.Country && lead.Country.name;

          delete lead.BusinessTypeId;
          delete lead.Country;
          delete lead.CountryId;
          delete lead.restrictedItems;

          return {
            ...lead,
            fullName: textFormatter.capitalize(lead.fullName),
            email: lead.email.toLowerCase(),
            businessName: textFormatter.capitalize(lead.businessName)
          };
        });

        draft.leadsForExport = list;
        break;
      case types.UPDATE_LEADS_COMPLETED:
        const updatedLeads = draft.leads.list
          .map(lead => {
            if (lead.id === payload.id) {
              return {
                ...lead,
                ...payload.lead
              };
            } else {
              return lead;
            }
          })
          .filter(lead => lead.id !== payload.id);

        draft.leads = {
          ...state.leads,
          list: updatedLeads
        };
        break;
      case types.UPDATE_LEADS_GROUP_COMPLETED:
        console.log("payload :>> ", payload);
      // const updatedGroupLeads = draft.leads.list
      //   .map(lead => {
      //     if (lead.id === payload.id) {
      //       return {
      //         ...lead,
      //         ...payload.lead
      //       };
      //     } else {
      //       return lead;
      //     }
      //   })
      //   .filter(lead => lead.id !== payload.id);

      // draft.leads = {
      //   ...state.leads,
      //   list: updatedLeads
      // };
      // break;
      case types.GET_EMAIL_TEMPLATES_COMPLETED:
        draft.emailTemplates = payload;
        break;
      default:
        return state;
    }
  });
};

export default leadReducers;
