import React from "react";
import { Switch, FormControlLabel } from "@material-ui/core";

export default function SwitchExtended({
  name,
  label,
  value,
  onChange,
  color = "primary"
}) {
  const handleChange = event => {
    onChange(event.target.checked);
  };

  return (
    <FormControlLabel
      control={
        <Switch
          checked={value}
          onChange={handleChange}
          name={name}
          color={color}
        />
      }
      label={label}
    />
  );
}
