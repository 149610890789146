import produce from "immer";
import * as types from "./types";

const initialState = {
  categories: [],
  businessTypes: [],
  countries: [],
  roles: []
};

const metaReducers = function (state = initialState, action) {
  const { type, payload } = action;

  return produce(state, draft => {
    switch (type) {
      case types.GET_CATEGORIES_COMPLETED:
        draft.categories = payload;
        break;
      case types.CREATE_CATEGORY_COMPLETED:
        draft.categories.unshift(payload);
        break;
      case types.GET_COUNTRIES_COMPLETED:
        draft.countries = payload;
        break;
      case types.GET_BUSINESSTYPES_COMPLETED:
        draft.businessTypes = payload;
        break;
      case types.GET_ROLES_COMPLETED:
        draft.roles = payload;
        break;
      default:
        return state;
    }
  });
};

export default metaReducers;
