import React from "react";
import { Grid } from "@material-ui/core";
import { LocalOfferOutlined } from "@material-ui/icons";
import { Wrapper, Button, Dialog, Typography, LazyLoadImage } from "views/ui";
import { assetsEndpoint } from "helpers/variables";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getPath, getCopyPath } from "./getImageInfo";

const ImageDetails = ({ image, open, loading, handleToggleChange }) => {
  const path = getPath(image.imageMeta, "600");

  return (
    <Dialog
      open={open}
      handleClose={handleToggleChange}
      btnDisabled={loading}
      loading={loading}
    >
      <Grid container>
        <Grid item xs={8}>
          <LazyLoadImage
            src={`${assetsEndpoint}/${image.imageId}/${image.imageId + path}`}
            alt="Gallery Image"
            width={image.imageMeta.width}
            height={image.imageMeta.height}
            placeholder={image.imageMeta.lqip}
          />

          <Wrapper display="flex" mt={3} borderTop={1} borderColor="grey.300">
            {image.tags.map(tag => (
              <Wrapper
                key={tag}
                display="flex"
                alignItems="center"
                mr={2}
                pt={2}
              >
                <LocalOfferOutlined />
                <Typography variant="body1" ml={1}>
                  {tag}
                </Typography>
              </Wrapper>
            ))}
          </Wrapper>
        </Grid>
        <Grid item xs={4}>
          <Wrapper px={2}>
            {image.imageMeta.availableVersions.map((version, index) => (
              <Wrapper
                display="flex"
                key={index}
                justifyContent="space-between"
                alignItems="center"
                borderBottom="1px solid"
                borderColor="#eeeeee"
                pb={1}
                mb={1}
              >
                <Typography width="50px">
                  {getCopyPath(image, version, index).name}
                </Typography>
                <CopyToClipboard text={getCopyPath(image, version, index).path}>
                  <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    mt={1}
                  >
                    Copy
                  </Button>
                </CopyToClipboard>
              </Wrapper>
            ))}
          </Wrapper>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ImageDetails;
