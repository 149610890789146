export const CREATE_BLOG = 'BLOGS/CREATE_BLOG';
export const CREATE_BLOG_COMPLETED = 'BLOGS/CREATE_BLOG_COMPLETED';
export const CREATE_BLOG_FAILED = 'BLOGS/CREATE_BLOG_FAILED';

export const GET_BLOGS = 'BLOGS/GET_BLOGS';
export const GET_BLOGS_COMPLETED = 'BLOGS/GET_BLOGS_COMPLETED';
export const GET_BLOGS_FAILED = 'BLOGS/GET_BLOGS_FAILED';

export const GET_BLOG = 'BLOGS/GET_BLOG';
export const GET_BLOG_COMPLETED = 'BLOGS/GET_BLOG_COMPLETED';
export const GET_BLOG_FAILED = 'BLOGS/GET_BLOG_FAILED';

export const UPDATE_BLOG = 'BLOGS/UPDATE_BLOG';
export const UPDATE_BLOG_COMPLETED = 'BLOGS/UPDATE_BLOG_COMPLETED';
export const UPDATE_BLOG_FAILED = 'BLOGS/UPDATE_BLOG_FAILED';

export const DELETE_BLOG = 'BLOGS/DELETE_BLOG';
export const DELETE_BLOG_COMPLETED = 'BLOGS/DELETE_BLOG_COMPLETED';
export const DELETE_BLOG_FAILED = 'BLOGS/DELETE_BLOG_FAILED';

export const EMPTY_BLOG_DATA = 'BLOGS/EMPTY_BLOG_DATA';
