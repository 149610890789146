import React from "react";
import { Form, withFormik } from "formik";
import { TextField } from "@material-ui/core";
import * as yup from "yup";

import { Button, Wrapper, FieldErrorMsg } from "views/ui";

const SendEmail = ({
  values,
  errors,
  touched,
  SendEmailError,
  handleChange,
  handleBlur,
  loading
}) => {
  return (
    <Wrapper textAlign="center">
      <Form>
        <TextField
          fullWidth
          label="Subject"
          name="subject"
          value={values.subject}
          margin="dense"
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.subject && touched.subject}
          variant="outlined"
        />
        <FieldErrorMsg error={errors.subject} isTouched={touched.subject} />
        <TextField
          error={errors.message && touched.message}
          fullWidth
          label="Message"
          name="message"
          type="message"
          value={values.message}
          margin="dense"
          onChange={handleChange}
          onBlur={handleBlur}
          variant="outlined"
          multiline={true}
          rows={4}
        />
        <FieldErrorMsg error={errors.message} isTouched={touched.message} />
        <FieldErrorMsg error={SendEmailError} isTouched={!!SendEmailError} />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          justify="flex-start"
          mt={2}
          type="submit"
          disabled={loading}
          loading={loading}
        >
          Send
        </Button>
      </Form>
    </Wrapper>
  );
};

const SendEmailForm = withFormik({
  mapPropsToValues: () => ({
    subject: "",
    message: ""
  }),
  validationSchema: yup.object().shape({
    subject: yup.string().required("Please enter your subject"),
    message: yup.string().required("Please enter your message")
  }),
  handleSubmit(values, { props: { handleSendEmail } }) {
    handleSendEmail(values);
  }
})(SendEmail);

export default SendEmailForm;
