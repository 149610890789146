import React from "react";
import { createEditorState, Editor, BLOCK_BUTTONS } from "medium-draft";
import "assets/css/editor.css";
import mediumDraftExporter from "medium-draft/lib/exporter";
import { convertToRaw } from "draft-js";
import mediumDraftImporter from "medium-draft/lib/importer";
import UploadImage from "./UploadImage";

const blockButtons = [
  {
    label: "H1",
    style: "header-one",
    icon: "header",
    description: "Heading 1"
  },
  {
    label: "H2",
    style: "header-two",
    icon: "header",
    description: "Heading 2"
  }
].concat(BLOCK_BUTTONS);

// Now pass this component instead of default prop to Editor example above.
class RichEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: createEditorState(
        convertToRaw(mediumDraftImporter(this.props.html || ""))
      )
    };
    this.refsEditor = React.createRef();
  }

  onChange = editorState => {
    this.setState({ editorState });
    this.props.handleHtml(mediumDraftExporter(editorState.getCurrentContent()));
  };

  render() {
    const { editorState } = this.state;

    const sideButtons = [
      {
        title: "Image",
        component: UploadImage
      }
    ];

    return (
      <Editor
        ref={this.refsEditor}
        editorState={editorState}
        onChange={this.onChange}
        sideButtons={sideButtons}
        blockButtons={blockButtons}
      />
    );
  }
}

export default RichEditor;
