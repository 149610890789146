import * as React from "react";

const DownloadIcon = props => (
  <svg
    width={17}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.808 13.538c0-.166-.061-.31-.183-.432a.591.591 0 0 0-.433-.183c-.166 0-.31.06-.432.183a.592.592 0 0 0-.183.432c0 .167.06.311.183.433a.591.591 0 0 0 .432.183c.167 0 .311-.061.433-.183a.591.591 0 0 0 .183-.433Zm2.461 0c0-.166-.06-.31-.183-.432a.591.591 0 0 0-.432-.183c-.167 0-.311.06-.433.183a.592.592 0 0 0-.182.432c0 .167.06.311.182.433a.591.591 0 0 0 .433.183c.166 0 .31-.061.432-.183a.591.591 0 0 0 .183-.433Zm1.231-2.153v3.076a.89.89 0 0 1-.27.654.89.89 0 0 1-.653.27H1.423a.89.89 0 0 1-.654-.27.89.89 0 0 1-.269-.654v-3.076a.89.89 0 0 1 .27-.654.89.89 0 0 1 .653-.27h4.471l1.298 1.308c.372.36.808.539 1.308.539s.936-.18 1.308-.539l1.307-1.308h4.462a.89.89 0 0 1 .654.27c.18.18.269.397.269.654Zm-3.125-5.472c.109.263.064.488-.135.674l-4.307 4.307a.571.571 0 0 1-.433.183.571.571 0 0 1-.433-.183L3.76 6.587c-.2-.186-.244-.41-.135-.674a.575.575 0 0 1 .567-.375h2.462V1.231c0-.167.06-.311.183-.433a.591.591 0 0 1 .432-.183h2.462c.166 0 .31.061.433.183a.591.591 0 0 1 .182.433v4.307h2.462c.269 0 .458.125.567.375Z"
      fill="#00C11F"
    />
  </svg>
);

export default DownloadIcon;
