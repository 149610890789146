import React from "react";
import { Wrapper, TextField, RichEditor, Button } from "views/ui";
import { withFormik } from "formik";
import { IconButton } from "@material-ui/core";
import { PlaylistAdd } from "@material-ui/icons";

const SubSectionForm = ({
  values,
  setFieldValue,
  handleChange,
  handleSubSection,
  subSection = {}
}) => {
  const handleHtml = markup => {
    setFieldValue("description", markup);
  };

  const handleAddMore = type => {
    switch (type) {
      case "subtitle":
        setFieldValue("subtitles", values.subtitles.concat(""));
        break;
      case "link":
        setFieldValue(
          "links",
          values.links.concat({
            name: "",
            url: ""
          })
        );
        break;
      default:
        break;
    }
  };

  const handleSubSectionEdit = () => {
    handleSubSection(values);
  };

  return (
    <Wrapper p={4}>
      <TextField
        mb={2}
        label="Title"
        margin="dense"
        variant="outlined"
        fullWidth
        name="title"
        value={values.title}
        rows={4}
        onChange={handleChange}
      />

      <Wrapper display="flex" alignItems="center">
        <Wrapper width="95%">
          {values.subtitles.map((subtitle, index) => (
            <TextField
              key={index}
              mb={2}
              label="Subtitle"
              margin="dense"
              variant="outlined"
              fullWidth
              name={`subtitles[${index}]`}
              value={subtitle}
              rows={4}
              onChange={handleChange}
            />
          ))}
        </Wrapper>

        <IconButton onClick={() => handleAddMore("subtitle")}>
          <PlaylistAdd />
        </IconButton>
      </Wrapper>

      <Wrapper mb={2} border={1} borderColor="grey.400" borderRadius={4}>
        <RichEditor
          name="content"
          html={values.description}
          handleHtml={handleHtml}
        />
      </Wrapper>

      <Wrapper display="flex" alignItems="center">
        <Wrapper width="95%">
          {values.links.map((link, index) => (
            <Wrapper display="flex" key={index} name={`links[${index}]`} mb={2}>
              <TextField
                label="Link Label"
                margin="dense"
                variant="outlined"
                width="200"
                name={`links[${index}].label`}
                value={link.label}
                rows={4}
                mr={1}
                onChange={handleChange}
              />
              <TextField
                label="Url"
                margin="dense"
                variant="outlined"
                fullWidth
                name={`links[${index}].url`}
                value={link.url}
                rows={4}
                onChange={handleChange}
              />
            </Wrapper>
          ))}
        </Wrapper>
        <IconButton onClick={() => handleAddMore("link")}>
          <PlaylistAdd />
        </IconButton>
      </Wrapper>

      <Wrapper display="flex" justifyContent="flex-end" mt={2}>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSubSectionEdit}
        >
          {subSection.id ? "Update" : "Add"}
        </Button>
      </Wrapper>
    </Wrapper>
  );
};

export default withFormik({
  mapPropsToValues: ({ subSection = {} }) => {
    const id = subSection.id || null;
    const title = subSection.title || "";
    const subtitles = subSection.subtitles || [""];
    const description = subSection.description || "";
    const links = subSection.links || [
      {
        label: "",
        url: ""
      }
    ];
    const images = subSection.images || [
      {
        name: "",
        url: ""
      }
    ];

    return {
      id,
      title,
      subtitles,
      description,
      links,
      images
    };
  }
})(SubSectionForm);
