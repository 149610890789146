import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { Button } from "../";

export default function Confirm({ open, handleClose, handleConfirm }) {
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="confirm-dialog">
      <DialogContent>
        <DialogContentText>
          Are you confirm to perform this action?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={handleClose}
          color="primary"
          variant="contained"
          size="small"
        >
          Close
        </Button>
        <Button onClick={handleConfirm} color="primary" size="small">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
