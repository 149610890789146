import React from 'react';
import { TextField } from '@material-ui/core';
import styled from 'styled-components';

const TextFieldStyled = styled(TextField)`
  margin-top: 0;
  margin-bottom: 0;

  input {
    padding-top: 9px;
    padding-bottom: 9px;
  }
`;

const TextFieldExtended = ({ value, placeholder, name, onChange }) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <TextFieldStyled
      value={value}
      name={name}
      placeholder={placeholder}
      variant="outlined"
      fullWidth={true}
      margin="dense"
      onChange={handleChange}
    />
  );
};

export default TextFieldExtended;
