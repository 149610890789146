export const apiEndpoint = process.env.REACT_APP_API;
export const assetsEndpoint = process.env.REACT_APP_IMAGE_URL;

export const rolesHierarchy = ["admin", "staff", "writer", "customer"];

export const userRoles = {
  admin: "admin",
  staff: "staff",
  writer: "writer",
  customer: "customer"
};

export const imageSizes = [
  "300",
  "600",
  "900",
  "1200",
  "1600",
  "2000",
  "2600",
  "original"
];
export const maxImageSize = 3 * 1024 * 1024;
