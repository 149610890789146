import styled from 'styled-components';
import { spacing, sizing, typography } from '@material-ui/system';

const OneLiner = styled.div`
  clear: both;
  display: inline-block;
  overflow-x: ${({ overflowX }) => (overflowX ? overflowX : 'hidden')};
  white-space: nowrap;

  ${spacing};
  ${sizing};
  ${typography};
`;

export default OneLiner;
