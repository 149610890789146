import React from 'react';

import Sites from 'views/components/Sites';
import { Wrapper } from 'views/ui';

const SitesPage = () => {
  return (
    <Wrapper p={3}>
      <Sites />
    </Wrapper>
  );
};

export default SitesPage;
