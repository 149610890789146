import produce from "immer";
import * as types from "./types";

const initialState = {
  error: {},
  loading: {},
  fetched: {}
};

const uiReducers = (state = initialState, action) => {
  const { type, payload } = action;

  return produce(state, draft => {
    switch (type) {
      case types.SET_ERROR_MESSAGE:
        draft.error = {
          [action.meta.label]: payload.message ? payload.message.title : payload
        };
        break;
      case types.CLEAR_ERROR_MESSAGE:
        draft.error = "";
        break;
      case types.FETCHING_START:
        draft.loading = {
          ...state.loading,
          [payload.label]: true
        };
        draft.fetched = {
          ...state.fetched,
          [payload.label]: false
        };
        break;
      case types.FETCHING_END:
        draft.loading = {
          ...state.loading,
          [payload.label]: false
        };
        draft.fetched = {
          ...state.loading,
          [payload.label]: true
        };
        break;
      default:
        return state;
    }
  });
};

export default uiReducers;
