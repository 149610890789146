import React, { useState } from "react";
import {
  Button,
  Grid,
  ButtonBase,
  Divider,
  IconButton
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { withFormik, Form } from "formik";
import {
  Delete,
  LibraryAdd,
  ArrowRightAlt,
  DateRange,
  Compare,
  Cancel
} from "@material-ui/icons";
import { format, compareAsc } from "date-fns";
import {
  DateFilter,
  SelectFilter,
  SectionBadge,
  SingleDate,
  TextFieldFilter,
  SwitchFieldFilter
} from ".";
import PropTypes from "prop-types";
import { Wrapper } from "views/ui";
import styled from "styled-components";

const datePopperId = "date-popper";

const ItemWrapper = styled.div`
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const CloseIcon = styled(ButtonBase)`
  position: absolute;
  top: -10px;
  right: -10px;
`;

const styles = theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    flexWrap: "wrap",

    [theme.breakpoints.up("md")]: {
      flexDirection: "row"
    }
  },
  item: {
    marginBottom: 15,
    [theme.breakpoints.up("md")]: {
      marginRight: 15,
      marginBottom: 0
    }
  },
  wrapper: {
    position: "relative",
  },
  compareBtn: {
    position: "absolute",
    bottom: -29,
    left: "50%",
    transform: "translateX(-50%)",
    background: "#fff",
    padding: "0 12px",
    boxShadow: "1px 2px 2px 0px rgba(0,0,0,0.2)",
    borderRadius: "0 0 4px 4px",
    height: 30,
    lineHeight: "30px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    borderTop: "1px dashed #bdbdbd",

    "& span": {
      marginLeft: 4,
      display: "inline-block",
      textTransform: "uppercase"
    }
  },
  vsTxt: {
    margin: "0 10px",
    fontSize: 18,
    fontWeight: 300
  }
});

function formatDate(date = null) {
  return format(new Date(date), "dd/MM/yyyy");
}

const MyFilter = ({ config, classes, values, setFieldValue }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currIndex, setCurrIndex] = useState(0);
  const maxSegment = config.type === "segment" ? config.maxSegment || 10 : 2; // 2 for compare
  const isNotDefault = config.type === "segment" || config.type === "compare";

  const handleDateFilterClose = (dates = {}, range) => {
    setFieldValue(`filters[${currIndex}].dates.startDate`, dates.startDate);
    setFieldValue(`filters[${currIndex}].dates.endDate`, dates.endDate);

    setFieldValue(`filters[${currIndex}].range`, range);
    setAnchorEl(null);
  };

  const handleSelectField = (option, name, index) => {
    setFieldValue(`filters[${index}].${name}`, option);
  };

  const handleSwitchChange = (value, name, index) => {
    setFieldValue(`filters[${index}].${name}`, value);
  };

  const handleTextFieldChange = (value, name, index) => {
    setFieldValue(`filters[${index}].${name}`, value.trim());
  };

  const handleSingleDateChange = (name, value) => {
    setFieldValue(
      `filters[${currIndex}].${name}`,
      new Date(value).toISOString()
    );
  };

  const handleDateFilterOpen = (event, index) => {
    setCurrIndex(index);
    setAnchorEl(event.currentTarget);
  };

  const addSegment = () => {
    const { filters } = values;

    const updatedFilters = filters.concat(filters[filters.length - 1]);

    setFieldValue("filters", updatedFilters);
  };

  const removeSegment = position => {
    const { filters } = values;

    const updatedFilters = filters.filter((_, index) => index !== position);
    setCurrIndex(0);
    setFieldValue("filters", updatedFilters);
  };

  const handleDeleteFilter = (name, index) => {
    if (name === "dates") {
      setFieldValue(`filters[${index}].dates.startDate`, null);
      setFieldValue(`filters[${index}].dates.endDate`, null);
      setFieldValue(`filters[${index}].range`, "");
    } else {
      setFieldValue(`filters[${index}].${name}`, "");
    }
  };

  return (
    <Form className={classes.wrapper}>
      {values.filters.map((filter, index) => (
        <div className={`relative ${isNotDefault && "pl-16"}`} key={index}>
          {isNotDefault && (
            <SectionBadge count={index + 1} colors={config.segmentColors} />
          )}
          {index > 0 && <Divider />}
          <Grid container>
            <Grid item xs={12} md={10}>
              <div className={classes.root}>
                {config.datePicker === "range" && (
                  <ItemWrapper>
                    <Button
                      color="default"
                      variant="outlined"
                      aria-owns={anchorEl ? datePopperId : undefined}
                      aria-haspopup="true"
                      onClick={event => handleDateFilterOpen(event, index)}
                    >
                      <>
                        {filter.dates.startDate && filter.dates.endDate ? (
                          <>
                            {formatDate(filter.dates.startDate)}{" "}
                            {compareAsc(
                              filter.dates.startDate,
                              filter.dates.endDate
                            ) !== 0 && (
                              <>
                                <ArrowRightAlt className="mx-8" />{" "}
                                {formatDate(filter.dates.endDate)}
                              </>
                            )}
                          </>
                        ) : (
                          <span
                            style={{
                              color: "rgba(0, 0, 0, 0.50)",
                              textTransform: "capitalize"
                              // fontSize: 18,
                            }}
                          >
                            Select Dates
                          </span>
                        )}
                      </>
                      <Wrapper ml={2} display="flex">
                        <DateRange
                          style={{
                            color: filter.dates.startDate
                              ? "rgba(0, 0, 0, 0.60)"
                              : "hsl(0,0%,50%)"
                          }}
                        />
                      </Wrapper>
                    </Button>
                    {filter.dates.startDate && filter.dates.endDate && (
                      <CloseIcon
                        onClick={() => handleDeleteFilter("dates", index)}
                      >
                        <Cancel color="error" />
                      </CloseIcon>
                    )}
                  </ItemWrapper>
                )}
                {config.datePicker === "single" && (
                  <div className={classes.item}>
                    <SingleDate
                      name="date"
                      value={filter.date}
                      handleDateChange={handleSingleDateChange}
                    />
                  </div>
                )}
                {config.select &&
                  config.select.map(item => (
                    <ItemWrapper key={item.name}>
                      <SelectFilter
                        options={item.options}
                        value={filter[item.name]}
                        name={`${item.name}[${index}]`}
                        placeholder={item.placeholder}
                        isMulti={item.isMulti}
                        width={item.width}
                        handleChange={value =>
                          handleSelectField(value, item.name, index)
                        }
                      />
                      {filter[item.name] && (
                        <CloseIcon
                          onClick={() => handleDeleteFilter(item.name, index)}
                        >
                          <Cancel color="error" />
                        </CloseIcon>
                      )}
                    </ItemWrapper>
                  ))}
                {config.textField.map(item => (
                  <ItemWrapper key={item.name}>
                    <TextFieldFilter
                      value={filter[item.name]}
                      name={`${item.name}[${index}]`}
                      placeholder={item.placeholder}
                      onChange={value =>
                        handleTextFieldChange(value, item.name, index)
                      }
                    />
                    {filter[item.name] && (
                      <CloseIcon
                        onClick={() => handleDeleteFilter(item.name, index)}
                      >
                        <Cancel color="error" />
                      </CloseIcon>
                    )}
                  </ItemWrapper>
                ))}
                {config.switchField &&
                  config.switchField.map(item => (
                    <ItemWrapper key={item.name}>
                      <SwitchFieldFilter
                        value={filter[item.name]}
                        name={`${item.name}[${index}]`}
                        label={item.label}
                        onChange={value =>
                          handleSwitchChange(value, item.name, index)
                        }
                      />
                    </ItemWrapper>
                  ))}
              </div>
            </Grid>
            <Grid item xs={12} md={2} style={{alignSelf: 'center'}}>
              {index === 0 ? (
                <Wrapper display="flex" justifyContent="flex-end">
                  <Button color="primary" variant="outlined" type="submit">
                    Apply
                  </Button>
                </Wrapper>
              ) : (
                <IconButton
                  onClick={() => removeSegment(index)}
                  className="p-0"
                >
                  <Delete />
                </IconButton>
              )}
            </Grid>
          </Grid>

          {config.datePicker === "range" && (
            <DateFilter
              initDates={{
                startDate: values.filters[currIndex].dates.startDate,
                endDate: values.filters[currIndex].dates.endDate
              }}
              initRange={values.filters[currIndex].range}
              id={datePopperId}
              handleDateFilterClose={handleDateFilterClose}
              anchorEl={anchorEl}
            />
          )}
        </div>
      ))}

      {(config.type === "segment" || config.type === "compare") &&
        maxSegment > values.filters.length && (
          <ButtonBase className={classes.compareBtn} onClick={addSegment}>
            {config.type === "segment" ? (
              <>
                <LibraryAdd />
                <span>Segment</span>
              </>
            ) : (
              <>
                <Compare />
                <span>Compare</span>
              </>
            )}
          </ButtonBase>
        )}
    </Form>
  );
};

const MyFilterForm = withFormik({
  mapPropsToValues: ({
    config: {
      select = [],
      textField = [],
      switchField = [],
      datePicker,
      defaultDateValues = {}
    }
  }) => {
    const { startDate, endDate, range = "" } = defaultDateValues;

    const selectMenu = {};
    textField.forEach(item => {
      selectMenu[item.name] = item.value || "";
    });

    const textInputs = {};
    select.forEach(item => {
      textInputs[item.name] = item.value || "";
    });

    const switchInputs = {};
    switchField.forEach(item => {
      switchInputs[item.name] = item.value || false;
    });

    const datePickerRange = {
      startDate: startDate ? startDate : null,
      endDate: endDate ? endDate : null
    };

    const dates = {
      ...(datePicker === "range" && { ...datePickerRange })
    };

    return {
      filters: [
        {
          ...(datePicker === "single" && { date: new Date() }),
          ...(datePicker === "range" && {
            range,
            dates
          }),
          ...selectMenu,
          ...textInputs,
          ...switchInputs
        }
      ]
    };
  },
  handleSubmit(values, { props: { handleFilter, config } }) {
    let data = values.filters;

    if (config.type === "default" || !config.type) {
      data = data[0];
    }

    handleFilter(data);
  }
})(MyFilter);

MyFilter.propTypes = {
  handleFilter: PropTypes.func.isRequired,
  config: PropTypes.shape({
    type: PropTypes.oneOf(["default", "segment", "compare"]),
    datePicker: PropTypes.oneOf(["range", "single"]),
    maxSegment: PropTypes.number,
    segmentColors: PropTypes.array,
    defaultDateValues: PropTypes.shape({
      startDate: PropTypes.instanceOf(Date),
      endDate: PropTypes.instanceOf(Date),
      range: PropTypes.oneOf([
        "currentDay",
        "yesterday",
        "last7Days",
        "last30Days",
        "last365Days"
      ])
    }),
    select: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        value: PropTypes.PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number
        ]),
        options: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
              .isRequired
          })
        ).isRequired
      })
    )
  })
};

export default withStyles(styles, { withTheme: true })(MyFilterForm);
