import React, { useEffect } from "react";
import { Drawer } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { Button, Wrapper, RichEditor, TextField } from "views/ui";

const FeatureForm = ({
  open,
  feature = {},
  handleCloseFeatureForm,
  handleFeatureUpdate,
  loading
}) => {
  const { register, handleSubmit, setValue } = useForm();

  useEffect(() => {
    register({ name: "description" });
  }, [register]);

  const handleHtml = markup => {
    setValue("description", markup);
  };

  return (
    <Drawer open={open} anchor="right" onClose={handleCloseFeatureForm}>
      <Wrapper p={4}>
        <form onSubmit={handleSubmit(handleFeatureUpdate)}>
          <TextField
            mb={3}
            label="Title"
            margin="dense"
            variant="outlined"
            fullWidth
            name="title"
            defaultValue={feature.title}
            inputRef={register}
            rows={4}
          />
          <Wrapper mb={2} border={1} borderColor="grey.400" borderRadius={4}>
            <RichEditor
              name="description"
              html={feature.description}
              handleHtml={handleHtml}
            />
          </Wrapper>
          <Button
            variant="outlined"
            color="primary"
            mt={2}
            mr={1}
            onClick={handleCloseFeatureForm}
          >
            Close
          </Button>

          <Button
            loading={loading["featureForm"]}
            disabled={loading["featureForm"]}
            variant="contained"
            color="primary"
            mt={2}
            type="submit"
          >
            {feature._id ? "Update" : "Add"}
          </Button>
        </form>
      </Wrapper>
    </Drawer>
  );
};

export default FeatureForm;
