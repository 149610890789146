import React from "react";
import { variables } from "helpers";
import { LazyLoadImage } from "views/ui";
import { getPath } from "./getImageInfo";

export default function ImageThumbnail({ imageId, imageMeta = {}, onClick }) {
  const path = getPath(imageMeta, "300");

  return (
    <LazyLoadImage
      src={`${variables.assetsEndpoint}/${imageId}/${imageId + path}`}
      alt="Gallery Image"
      width={150}
      height={100}
      placeholderWidth={150}
      placeholderHeight={100}
      placeholder={imageMeta.lqip}
      onClick={onClick}
      cursor
    />
  );
}
