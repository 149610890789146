import React, { useState } from "react";
import {
  Wrapper,
  TextField,
  Paper,
  Switch,
  Checkbox,
  Typography,
  Button,
  Confirm
} from "views/ui";
import { Form, withFormik } from "formik";
import { IconButton } from "@material-ui/core";
import { PlaylistAdd, RemoveCircle } from "@material-ui/icons";
import { useToggle } from "hooks";

const SiteNavForm = ({
  open,
  loading,
  values,
  setFieldValue,
  handleChange
}) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useToggle(false);
  const [pageIndex, setPageIndex] = useState(null);

  const handleAddMore = () => {
    setFieldValue(
      "links",
      values.links.concat({
        label: "",
        url: "",
        order: 0,
        isActive: true,
        isOpenNewTab: false
      })
    );
  };

  const handleRemove = position => {
    setPageIndex(position);
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setPageIndex(null);
    setOpenConfirmDialog(false);
  };

  const handleConfirm = () => {
    const updatedPages = values.pages.filter((_, index) => index !== pageIndex);
    setFieldValue("pages", updatedPages);
    setOpenConfirmDialog(false);
  };

  return (
    <Paper p={3}>
      <Typography variant="h4" align="center" mb={2}>
        Site Navigation
      </Typography>
      <Form>
        <Wrapper display="flex" alignItems="center">
          <Wrapper width="95%">
            {values.links.map((link, index) => (
              <Wrapper
                display="flex"
                key={index}
                name={`links[${index}]`}
                mb={2}
              >
                <Switch
                  name={`links[${index}].isActive`}
                  value={link.isActive}
                  onChange={(name, value) =>
                    setFieldValue(`links[${index}].isActive`, value)
                  }
                />
                <Checkbox
                  name={`links[${index}].isOpenNewTab`}
                  value={link.isOpenNewTab}
                  onChange={(name, value) =>
                    setFieldValue(`links[${index}].isOpenNewTab`, value)
                  }
                />
                <TextField
                  label="Label"
                  margin="dense"
                  variant="outlined"
                  width="200"
                  name={`links[${index}].label`}
                  value={link.label}
                  rows={4}
                  mr={1}
                  onChange={handleChange}
                />
                <TextField
                  label="Url"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  name={`links[${index}].url`}
                  value={link.url}
                  rows={4}
                  onChange={handleChange}
                />
                {index === values.links.length - 1 && (
                  <IconButton onClick={handleAddMore}>
                    <PlaylistAdd />
                  </IconButton>
                )}
                {index !== values.links.length - 1 && (
                  <IconButton onClick={() => handleRemove(index)}>
                    <RemoveCircle />
                  </IconButton>
                )}
              </Wrapper>
            ))}
          </Wrapper>
        </Wrapper>

        <Wrapper display="flex" justifyContent="flex-end">
          <Button
            // loading={loading["siteForm"]}
            // disabled={}
            variant="contained"
            color="primary"
            mt={2}
            type="submit"
            size="small"
          >
            Update Nav
          </Button>
        </Wrapper>
      </Form>
      <Confirm
        open={openConfirmDialog}
        handleClose={handleCloseConfirmDialog}
        handleConfirm={handleConfirm}
      />
    </Paper>
  );
};

export default withFormik({
  mapPropsToValues: ({ links = [] }) => ({
    links: links.length
      ? links
      : [
          {
            label: "",
            url: "",
            isActive: true,
            isOpenNewTab: false
          }
        ]
  }),
  handleSubmit: (values, { props: { handleSiteNav } }) => {
    handleSiteNav(values);
  }
})(SiteNavForm);
