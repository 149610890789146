import React from "react";
import styled from "styled-components";
import { IconButton } from "@material-ui/core";
import { Cancel } from "@material-ui/icons";

const ImageWrapper = styled.div`
  position: relative;
`;

const ImageCrossBtn = styled(IconButton)`
  position: absolute;
  right: -33px;
  top: -37px;
`;

const ImageBlock = styled.div`
  display: inline-flex;
  border-radius: 2px;
  padding: 4px;
  box-sizing: border-box;
`;

const ImageBg = styled.div`
  background: url(${({ src }) => src});
  background-size: contain;
  width: 450px;
  height: 300px;
  background-repeat: no-repeat;
  background-position: center, center;
`;

const PreviewWrapper = styled.div`
  position: absolute;
  top: 0;
`;

const Preview = ({ files, handleImageRemove }) => {
  return (
    <PreviewWrapper>
      {files.map((file, index) => (
        <ImageBlock key={file.name}>
          <ImageWrapper>
            <ImageBg src={file.preview} />
            <ImageCrossBtn onClick={() => handleImageRemove(index)}>
              <Cancel />
            </ImageCrossBtn>
          </ImageWrapper>
        </ImageBlock>
      ))}
    </PreviewWrapper>
  );
};

export default Preview;
