import * as types from "./types";

export const login = data => ({
  type: types.LOGIN,
  payload: {
    path: `login`,
    method: "POST",
    data
  },
  meta: {
    API: true,
    jwt: false,
    label: "auth",
    loadMore: false
  }
});

export const signup = data => ({
  type: types.SIGNUP,
  payload: {
    path: `signup`,
    method: "POST",
    data
  },
  meta: {
    API: true,
    jwt: false,
    label: "auth",
    loadMore: false
  }
});

export const logout = () => ({
  type: types.LOGOUT,
  payload: {
    path: `logout`,
    method: "POST"
  },
  meta: {
    API: true,
    jwt: true,
    label: "auth",
    loadMore: false
  }
});

export const resetStore = () => ({
  type: types.RESET_STORE,
  meta: {
    API: false,
    label: "auth",
    loadMore: false
  }
});
