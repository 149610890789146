import React from "react";
import { Form, withFormik } from "formik";
import { TextField } from "@material-ui/core";
import * as yup from "yup";
import { Button, Wrapper, FieldErrorMsg, Select } from "views/ui";
import { textFormatter } from "utils";

function prepareOptions(roles = []) {
  return roles.map(role => ({
    label: textFormatter.capitalize(role.name),
    value: role.id
  }));
}

const Signup = ({
  values,
  errors,
  touched,
  signupError,
  handleChange,
  handleBlur,
  loading,
  setFieldValue,
  roles
}) => {
  return (
    <Wrapper textAlign="center">
      <Form>
        <TextField
          fullWidth
          label="Name"
          name="fullName"
          value={values.fullName}
          margin="normal"
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.fullName && touched.fullName}
        />
        <FieldErrorMsg error={errors.fullName} isTouched={touched.fullName} />
        <TextField
          fullWidth
          label="Email"
          name="email"
          value={values.email}
          margin="normal"
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors.email && touched.email}
        />
        <FieldErrorMsg error={errors.email} isTouched={touched.email} />
        <TextField
          error={errors.password && touched.password}
          fullWidth
          label="Password"
          name="password"
          type="password"
          value={values.password}
          margin="normal"
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <FieldErrorMsg error={errors.password} isTouched={touched.password} />
        <Select
          options={prepareOptions(roles)}
          my={3}
          name="roleId"
          onChange={option => setFieldValue("roleId", option.value)}
        />
        <FieldErrorMsg error={errors.roleId} isTouched={touched.roleId} />
        <FieldErrorMsg error={signupError} isTouched={!!signupError} />
        <Button
          fullWidth
          variant="contained"
          color="primary"
          justify="flex-start"
          mt={2}
          type="submit"
          disabled={loading}
          loading={loading}
        >
          Signup
        </Button>
      </Form>
    </Wrapper>
  );
};

const SignupForm = withFormik({
  mapPropsToValues: () => ({
    email: "",
    password: "",
    fullName: "",
    roleId: ""
  }),
  validationSchema: yup.object().shape({
    fullName: yup.string().required("Please enter name"),
    roleId: yup.string().required("Please enter role"),
    email: yup
      .string()
      .email("Please enter a valid email")
      .required("Please enter email"),
    password: yup.mixed().required("Please enter password")
  }),
  handleSubmit(values, { props: { handleSignup } }) {
    handleSignup(values);
  }
})(Signup);

export default SignupForm;
