import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { Button, Wrapper, Switch, FieldErrorMsg } from "../../";
import Select from "react-select/creatable";
import Preview from "./Preview";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Typography } from "@material-ui/core";
import { maxImageSize } from "helpers/variables";

const imageSchema = Yup.object().shape({
  files: Yup.mixed().required("Please upload image"),
  tags: Yup.array().required("Please add tags, It will help to search images"),
  newTags: Yup.array(),
  isPNG: Yup.boolean()
});

const getColor = props => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "#1976d2";
  }
  return "#232f3e40";
};

const DropzoneWrapper = styled.div`
  border-color: ${props => getColor(props)};
  border-style: dashed;
  border-width: 2px;
  padding: 15px;
  position: relative;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 120px 20px;
  height: 300px;
  width: 450px;
  border-radius: 2px;
  background-color: ${({ hasFile }) =>
    hasFile ? "#ffffff" : "rgba(196, 196, 196, 0.3)"};
  color: #2d3436;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;
  position: relative;
  text-align: center;
`;

function getImageTags(tags = []) {
  return tags.map(tag => ({
    label: tag.name,
    value: tag.name
  }));
}

export default function Dropzone({ imageTags = [], loading, handleUpload }) {
  const [isFormatPNG, setFormatPNG] = useState(false);
  const [error, setError] = useState("");
  const { values, errors, touched, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      files: [],
      tags: [],
      newTags: [],
      isPNG: false
    },
    validationSchema: imageSchema,
    onSubmit: values => {
      handleUpload({
        ...values,
        file: values.files[0],
        tags: values.tags.map(tag => tag.value.toLowerCase()),
        newTags: values.newTags.map(tag => tag.value.toLowerCase())
      });
    }
  });

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop: acceptedFiles => {
      const files = acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );
      if (files[0].size > maxImageSize) {
        setError(
          `Please upload a image less than ${maxImageSize / 1024 / 1024}MB`
        );
      } else {
        setError("");
        setFieldValue("files", files);
        setFormatPNG(files[0].type.includes("png"));
      }
    },
    multiple: false
  });

  const handleImageRemove = () => {
    setFieldValue("files", []);
  };

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      values.files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [values.files]
  );

  const handleTagChange = async (options = []) => {
    const newTags = (options || []).filter(item => item.__isNew__);

    setFieldValue("tags", options);
    setFieldValue("newTags", newTags);
  };

  return (
    <Wrapper display="flex">
      <DropzoneWrapper>
        <Container
          hasFile={values.files.length !== 0}
          {...getRootProps({ className: "dropzone" })}
        >
          <input {...getInputProps()} />
          {values.files.length === 0 && (
            <p>Drag 'n' drop some files here, or click to select files</p>
          )}
          <Preview files={values.files} handleImageRemove={handleImageRemove} />
        </Container>
      </DropzoneWrapper>
      <Wrapper ml={2} width="40%">
        <form onSubmit={handleSubmit}>
          <Wrapper display="flex" flexDirection="column" height="335px">
            <Wrapper flexGrow={1}>
              <Wrapper mb={2}>
                <Select
                  name="tags"
                  placeholder="Add Tags, write to create new tag"
                  value={values.tags}
                  onChange={handleTagChange}
                  options={getImageTags(imageTags)}
                  isClearable
                  isMulti
                />
                <FieldErrorMsg error={errors.tags} isTouched={touched.tags} />
              </Wrapper>
              {isFormatPNG && (
                <Wrapper>
                  <Switch
                    name="isPNG"
                    value={values.isPNG}
                    label="Keep background transparent"
                    onChange={setFieldValue}
                  />
                  <FieldErrorMsg
                    error={errors.isPNG}
                    isTouched={touched.isPNG}
                  />
                </Wrapper>
              )}
              {values.isPNG && (
                <Typography variant="subtitle1">
                  Transparent (PNG) image normally compress less than JPEG. If
                  you don't need transparent background, please use turn of
                  background transparency.
                </Typography>
              )}
              {error && <Typography color="error">{error}</Typography>}
            </Wrapper>
            <Wrapper display="flex" justifyContent="flex-end">
              <Button
                loading={loading}
                disabled={values.files.length === 0 || loading}
                variant="outlined"
                color="primary"
                type="submit"
              >
                Upload
              </Button>
            </Wrapper>
          </Wrapper>
        </form>
      </Wrapper>
    </Wrapper>
  );
}
