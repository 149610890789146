import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import UploadThumbnail from "./UploadThumbnail";
import CategoryForm from "./CategoryForm";
import {
  Typography,
  Paper,
  Button,
  Wrapper,
  FieldErrorMsg,
  RichEditor
} from "views/ui";
import styled from "styled-components";
import { Form, withFormik } from "formik";
import { format } from "date-fns";
import * as Yup from "yup";

const InputField = styled.input`
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 1.1876em;
  margin: 0;
  display: block;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  -webkit-tap-highlight-color: transparent;
  background: #fff;
  margin-bottom: 20px;
  padding: 30px 20px;
  box-sizing: border-box;

  :focus {
    outline: none;
  }

  :placeholder {
    color: rgba(0, 0, 0, 0.87);
  }
`;

const BlogForm = ({
  blog = {},
  values,
  setFieldValue,
  setFieldTouched,
  handleChange,
  handleBlogUpdate,
  loading,
  errors,
  touched
}) => {
  const [clickedButton, setClickedButton] = useState("");
  const [error, setError] = useState("");

  const handleHtml = markup => {
    setFieldValue("content", markup);
  };

  const handleThumbnail = url => {
    setFieldValue("thumbnail", url);
  };

  const handleSubmit = type => {
    if (!values.title.trim()) {
      setError("Please provide your blog title.");
      return;
    }
    setClickedButton(type);
    handleBlogUpdate(type, values);
  };

  const handleCategoryAttach = option => {
    setFieldValue("CategoryId", option.value);
  };

  return (
    <Form>
      <Grid container spacing={4}>
        <Grid item xs={9}>
          <InputField
            value={values.title}
            name="title"
            onChange={handleChange}
            placeholder="Write your title here..."
            onBlur={setFieldTouched}
            error={errors.title || error}
          />
          <FieldErrorMsg
            error={errors.title || error}
            isTouched={touched.title || error}
          />
          <RichEditor
            name="content"
            html={values.content}
            handleHtml={handleHtml}
          />
        </Grid>
        <Grid item xs={3}>
          <Paper elevation={0} p={3}>
            <Wrapper mb={2} borderBottom={1} pb={2}>
              <Typography>
                <strong>Status:</strong> {blog.status || "Not published yet"}
              </Typography>
              <Typography>
                <strong>Published Date:</strong>{" "}
                {blog.createdAt
                  ? format(new Date(blog.createdAt), "MM/dd/yyyy")
                  : "Not published yet"}
              </Typography>
              <Typography>
                <strong>Last Updated:</strong>{" "}
                {blog.updatedAt
                  ? format(new Date(blog.updatedAt), "MM/dd/yyyy")
                  : "Not published yet"}
              </Typography>
            </Wrapper>
            <Wrapper display="flex">
              {blog.status !== "unpublished" && (
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  mr={1}
                  loading={loading && clickedButton === "draft"}
                  disabled={loading && clickedButton === "draft"}
                  onClick={() => handleSubmit("draft")}
                >
                  {blog.status === "published" ? "Unpublish" : "Draft"}
                </Button>
              )}
              {blog.status === "unpublished" && (
                <Button
                  size="small"
                  variant="outlined"
                  mr={1}
                  loading={loading && clickedButton === "delete"}
                  disabled={loading && clickedButton === "delete"}
                  onClick={() => handleSubmit("delete")}
                >
                  Delete
                </Button>
              )}
              <Button
                color="primary"
                variant="contained"
                size="small"
                loading={loading && clickedButton === "publish"}
                disabled={loading && clickedButton === "publish"}
                onClick={() => handleSubmit("publish")}
              >
                {blog.status === "published" ? "Update" : "Publish"}
              </Button>
            </Wrapper>
          </Paper>
          <Paper elevation={0} p={3} mt={2}>
            <UploadThumbnail
              handleThumbnail={handleThumbnail}
              url={values.thumbnail}
            />
          </Paper>
          <Paper elevation={0} p={3} mt={2}>
            <CategoryForm
              handleCategoryAttach={handleCategoryAttach}
              category={blog.Category}
            />
          </Paper>
        </Grid>
      </Grid>
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: ({ blog = {} }) => {
    const title = blog.title || "";
    const content = blog.content || "";
    const thumbnail = blog.thumbnail || "";
    const CategoryId = blog.CategoryId || "";

    return {
      title,
      content,
      thumbnail,
      CategoryId
    };
  },
  validationSchema: Yup.object().shape({
    title: Yup.string().required("Please provide your blog title")
  })
})(BlogForm);
