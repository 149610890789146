import produce from 'immer';
import * as types from './types';

const initialState = {
  jenkins: {},
};

const jenkinsReducers = function (state = initialState, action) {
  const { type, payload } = action;

  return produce(state, (draft) => {
    switch (type) {
      case types.BUILD_SITE_COMPLETED:
        draft.site = payload;
        break;
      default:
        return state;
    }
  });
};

export default jenkinsReducers;
