import React from 'react';

import withSVG from './withSVG';

const GigIcon = () => (
  <path d="M 25.5 0 L 21 7.8125 L 25.3125 7.8125 L 23.1875 11.59375 L 22.09375 10.3125 L 21.09375 16 L 26.40625 12.8125 L 24.40625 12.09375 L 29 6 L 24.59375 6 L 28.90625 0 Z M 4 18 C 1.789063 18 0 19.789063 0 22 L 0 41.8125 C 0 42.835938 0.359375 43.863281 1.1875 44.59375 C 1.210938 44.613281 1.226563 44.636719 1.25 44.65625 L 5.40625 48.8125 C 6.164063 49.570313 7.136719 50 8.1875 50 L 41.6875 50 C 42.710938 50 43.769531 49.640625 44.5 48.8125 C 44.519531 48.789063 44.542969 48.773438 44.5625 48.75 L 48.71875 44.59375 C 49.476563 43.835938 49.90625 42.863281 49.90625 41.8125 L 49.90625 22.0625 C 49.90625 22.042969 49.90625 22.019531 49.90625 22 C 50 19.792969 48.1875 18 46 18 Z M 4 20 L 46 20 C 47.191406 20 47.976563 20.773438 47.90625 21.9375 L 47.90625 41.8125 C 47.90625 42.363281 47.722656 42.746094 47.28125 43.1875 L 43.09375 47.40625 L 43.03125 47.46875 C 42.761719 47.792969 42.238281 48 41.6875 48 L 8.1875 48 C 7.636719 48 7.253906 47.847656 6.8125 47.40625 L 2.53125 43.125 C 2.207031 42.855469 2 42.363281 2 41.8125 L 2 22 C 2 20.808594 2.808594 20 4 20 Z M 6.8125 27 C 6.335938 27.089844 5.992188 27.511719 6 28 L 6 35 C 6 35.550781 6.449219 36 7 36 L 43 36 C 43.550781 36 44 35.550781 44 35 L 44 28 C 44 27.449219 43.550781 27 43 27 L 7 27 C 6.96875 27 6.9375 27 6.90625 27 C 6.875 27 6.84375 27 6.8125 27 Z M 8 29 L 42 29 L 42 34 L 8 34 Z" />
);

export default withSVG(GigIcon, {
  viewBox: '0 0 50 50',
  size: '40',
  fill: '#1976d2',
});
