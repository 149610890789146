import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import LazyLoad from "react-lazyload";
import { Placeholder } from "./";

// const fadeInImg = keyframes`
//   from {
//     opacity: 0;
//   }

//   to {
//     opacity: 1;
//   }
// `;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: ${({ paddingBottom }) => paddingBottom}%;
  cursor: ${({ cursor }) => (cursor ? "pointer" : "inherit")};
`;

const Image = styled.img`
  position: absolute;
  left: 0;
  width: ${({ width }) => (width ? `${width}px` : "100%")};
  height: ${({ height }) => (height ? `${height}px` : "100%")};
  object-fit: cover;
`;

const LazyImage = ({
  src,
  alt,
  placeholder,
  placeholderWidth,
  placeholderHeight,
  width,
  height,
  onClick,
  cursor = ""
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const removePlaceholder = () => {
    setImageLoaded(true);
  };

  return (
    <ImageWrapper
      paddingBottom={(height / width) * 100}
      cursor={cursor.toString()}
    >
      {!imageLoaded && (
        <Placeholder
          width={placeholderWidth}
          height={placeholderHeight}
          placeholder={placeholder}
        />
      )}
      <LazyLoad>
        <Image
          onLoad={removePlaceholder}
          onError={removePlaceholder}
          src={src}
          alt={alt}
          onClick={onClick}
        />
      </LazyLoad>
    </ImageWrapper>
  );
};

LazyImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
};

export default LazyImage;
