import * as types from './types';

export const buildSite = (params) => {
  return {
    type: types.BUILD_SITE,
    payload: {
      path: `build-site`,
      method: 'GET',
      params,
    },
    meta: {
      API: true,
      label: 'buildSite',
      loadMore: false,
    },
  };
};
