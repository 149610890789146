import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
} from '@material-ui/core';
import { EditOutlined, DeleteOutlined } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { Wrapper, Typography, Paper, Button } from 'views/ui';

const BlogList = ({
  blogs: { list = [], total },
  page,
  limit,
  handleChangePage,
  handleChangeLimit,
  handleConfirmDialog,
  handleBuildSite,
  siteBuildInProgress,
}) => {
  return (
    <Wrapper>
      <Wrapper display="flex" justifyContent="space-between">
        <Typography variant="h4" mb={3}>
          Total Blogs: {total}
        </Typography>
        <Wrapper>
          <Button
            color="primary"
            variant="outlined"
            size="small"
            mr={2}
            onClick={handleBuildSite}
            loading={siteBuildInProgress}
            disabled={siteBuildInProgress}
          >
            Build Site
          </Button>
          <Button
            color="primary"
            variant="contained"
            size="small"
            component={Link}
            to="/blogs/add"
          >
            Create Blog
          </Button>
        </Wrapper>
      </Wrapper>
      {list.length ? (
        <TableContainer component={Paper}>
          <Table aria-label="Leads table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1">Title</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">Content</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">Status</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">Created At</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">Updated At</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">Actions</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((blog) => (
                <TableRow key={blog.id}>
                  <TableCell>
                    <Wrapper width="100px">{blog.title}</Wrapper>
                  </TableCell>
                  <TableCell>
                    <Wrapper
                      width="200px"
                      dangerouslySetInnerHTML={{
                        __html: blog.content.slice(0, 100),
                      }}
                    />
                  </TableCell>
                  <TableCell>{blog.status}</TableCell>
                  <TableCell>
                    {format(new Date(blog.createdAt), 'MM/dd/yyyy')}
                  </TableCell>
                  <TableCell>
                    {format(new Date(blog.updatedAt), 'MM/dd/yyyy')}
                  </TableCell>
                  <TableCell>
                    <Wrapper>
                      <IconButton component={Link} to={`/blogs/${blog.id}`}>
                        <EditOutlined />
                      </IconButton>
                      <IconButton onClick={() => handleConfirmDialog(blog.id)}>
                        <DeleteOutlined />
                      </IconButton>
                    </Wrapper>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={total}
            rowsPerPage={limit}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeLimit}
          />
        </TableContainer>
      ) : (
        <Paper p={4}>
          <Typography align="center" variant="body1">
            No Blog found!
          </Typography>
        </Paper>
      )}
    </Wrapper>
  );
};

export default BlogList;
