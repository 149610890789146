export const SIGNUP = 'AUTH/SIGNUP';
export const SIGNUP_COMPLETED = 'AUTH/SIGNUP_COMPLETED';
export const SIGNUP_FAILED = 'AUTH/SIGNUP_FAILED';

export const LOGIN = 'AUTH/LOGIN';
export const LOGIN_COMPLETED = 'AUTH/LOGIN_COMPLETED';
export const LOGIN_FAILED = 'AUTH/LOGIN_FAILED';

export const LOGOUT = 'AUTH/LOGOUT';
export const LOGOUT_COMPLETED = 'AUTH/LOGOUT_COMPLETED';
export const LOGOUT_FAILED = 'AUTH/LOGOUT_FAILED';

export const RESET_STORE = 'AUTH/RESET_STORE';
export const RESET_STORE_COMPLETED = 'AUTH/RESET_STORE_COMPLETED';
export const RESET_STORE_FAILED = 'AUTH/RESET_STORE_FAILED';
