import React, { useEffect } from "react";
import BlogForm from "./BlogForm";
import { useDispatch, useSelector } from "react-redux";
import { blogActions } from "store/blogs";
import { useParams, useHistory } from "react-router-dom";

const BlogEditContainer = () => {
  const dispatch = useDispatch();
  const { getBlog, updateBlog, emptyBlogData, deleteBlog } = blogActions;
  const blog = useSelector(({ blogs }) => blogs.blog);
  const loading = useSelector(({ ui }) => ui.loading["blogs"]);
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    dispatch(getBlog(id));

    return () => {
      dispatch(emptyBlogData());
    };
  }, [id, dispatch, getBlog, emptyBlogData]);

  const handleBlogUpdate = (type, data) => {
    let status = blog.status;

    if (blog.status === "unpublished" && type === "publish") {
      status = "published";
    }

    if (blog.status === "published" && type === "draft") {
      status = "unpublished";
    }

    const updatedData = {
      ...blog,
      ...data,
      status
    };

    if (type === "delete") {
      dispatch(deleteBlog(id));
      history.push("/blogs");
    } else {
      dispatch(updateBlog(id, updatedData));
    }
  };

  return (
    <>
      {blog.id ? (
        <BlogForm
          blog={blog}
          handleBlogUpdate={handleBlogUpdate}
          loading={loading}
        />
      ) : (
        "Loading.."
      )}
    </>
  );
};

export default BlogEditContainer;
