import React, { useState } from 'react';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { Calendar } from 'react-modern-calendar-datepicker';
import { getDate, getYear, getMonth } from 'date-fns';

const getFormattedDate = (date) => {
  const dateObj = new Date(date);
  return {
    day: getDate(dateObj),
    month: getMonth(dateObj),
    year: getYear(dateObj),
  };
};

const getDateObject = (date = {}) => {
  const { day, month, year } = date;
  return new Date(year, month, day);
};

const DatePickerExtended = ({ onChange, dates, footerComponent }) => {
  const [selectedDayRange, setSelectedDayRange] = useState({
    from: dates.startDate ? getFormattedDate(dates.startDate) : null,
    to: dates.endDate ? getFormattedDate(dates.endDate) : null,
  });

  const handleChange = ({ from, to }) => {
    setSelectedDayRange({ from, to });
    onChange({
      startDate: getDateObject(from),
      endDate: getDateObject(to || from),
    });
  };

  return (
    <Calendar
      // maximumDate={getFormattedDate(new Date())}
      value={selectedDayRange}
      onChange={handleChange}
      renderFooter={() => footerComponent}
    />
  );
};

export default DatePickerExtended;
