import * as types from './types';

export const createBlog = (data) => {
  return {
    type: types.CREATE_BLOG,
    payload: {
      path: `blogs/`,
      method: 'POST',
      data,
    },
    meta: {
      API: true,
      jwt: true,
      label: 'blogs',
      loadMore: false,
    },
  };
};

export const getBlogs = (params) => {
  return {
    type: types.GET_BLOGS,
    payload: {
      path: `blogs`,
      method: 'GET',
      params,
    },
    meta: {
      API: true,
      jwt: true,
      label: 'blogs',
      loadMore: false,
    },
  };
};

export const getBlog = (id) => {
  return {
    type: types.GET_BLOG,
    payload: {
      path: `blogs/${id}`,
      method: 'GET',
    },
    meta: {
      API: true,
      jwt: true,
      label: 'blogs',
      loadMore: false,
    },
  };
};

export const updateBlog = (id, data) => {
  return {
    type: types.UPDATE_BLOG,
    payload: {
      path: `blogs/${id}`,
      method: 'PUT',
      data,
    },
    meta: {
      API: true,
      jwt: true,
      label: 'blogs',
      loadMore: false,
    },
  };
};

export const deleteBlog = (id) => {
  return {
    type: types.DELETE_BLOG,
    payload: {
      path: `blogs/${id}`,
      method: 'DELETE',
    },
    meta: {
      API: true,
      jwt: true,
      label: 'blogs',
      loadMore: false,
    },
  };
};

export const emptyBlogData = () => {
  return {
    type: types.EMPTY_BLOG_DATA,
    payload: {},
    meta: {
      API: false,
      jwt: false,
      label: 'blogs',
      loadMore: false,
    },
  };
};
