export const GET_CATEGORIES = "SUPPLEMENT/GET_CATEGORIES";
export const GET_CATEGORIES_COMPLETED = "SUPPLEMENT/GET_CATEGORIES_COMPLETED";

export const CREATE_CATEGORY = "SUPPLEMENT/CREATE_CATEGORY";
export const CREATE_CATEGORY_COMPLETED = "SUPPLEMENT/CREATE_CATEGORY_COMPLETED";

export const GET_COUNTRIES = "SUPPLEMENT/GET_COUNTRIES";
export const GET_COUNTRIES_COMPLETED = "SUPPLEMENT/GET_COUNTRIES_COMPLETED";

export const GET_BUSINESSTYPES = "SUPPLEMENT/GET_BUSINESSTYPES";
export const GET_BUSINESSTYPES_COMPLETED =
  "SUPPLEMENT/GET_BUSINESSTYPES_COMPLETED";

export const GET_ROLES = "SUPPLEMENT/GET_ROLES";
export const GET_ROLES_COMPLETED = "SUPPLEMENT/GET_ROLES_COMPLETED";
