import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { variables } from "helpers";
import { authActions } from "store/auth";
import Login from "./Login";

const LoginContainer = () => {
  const dispatch = useDispatch();
  const { jwt, role } = useSelector(({ auth }) => ({
    jwt: auth.jwt,
    role: auth.user && auth.user.Role && auth.user.Role.name
  }));
  const { error, loading } = useSelector(({ ui }) => ({
    error: ui.error,
    loading: ui.loading
  }));
  // const [error, setError] = useState('');
  const { login } = authActions;

  const handleLogin = async values => {
    try {
      dispatch(login(values));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {jwt && role === variables.userRoles.admin && <Redirect to="/leads" />}
      {jwt && role === variables.userRoles.writer && <Redirect to="/blogs" />}
      {jwt && role === variables.userRoles.staff && (
        <Redirect to="/galleries-v2" />
      )}
      <Login
        loading={loading.auth}
        handleLogin={handleLogin}
        loginError={error["auth"] && "Email or Password not matched"}
      />
    </>
  );
};

export default LoginContainer;
