import React from "react";
import Select from "react-select";
import Wrapper from "./Wrapper";
import styled from "styled-components";

const SelectStyle = styled(Select)`
  width: 100%;
`;

const SelectExtended = ({
  name,
  value,
  options = [],
  onChange,
  isMulti,
  defaultValue,
  placeholder,
  width = "100%",
  ...rest
}) => {
  return (
    <Wrapper {...rest} width={width}>
      <SelectStyle
        name={name}
        value={value}
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={onChange}
        options={options}
        isMulti={isMulti}
      />
    </Wrapper>
  );
};

export default SelectExtended;
