import React from 'react';

import withSVG from './withSVG';

const FreelancerIcon = () => (
  <path d="M 7 3 C 5.34375 3 4 4.34375 4 6 C 4 7.65625 5.34375 9 7 9 C 8.65625 9 10 7.65625 10 6 C 10 4.34375 8.65625 3 7 3 Z M 24.34375 3.15625 C 24.15625 3.140625 23.964844 3.226563 23.875 3.40625 L 17.59375 15.8125 C 17.472656 16.050781 17.566406 16.328125 17.8125 16.4375 L 19.25 17.0625 C 19.496094 17.171875 19.765625 17.058594 19.875 16.8125 L 22.3125 11.3125 L 22.15625 17.875 L 17.125 17.875 C 17.113281 17.875 17.105469 17.875 17.09375 17.875 C 17.007813 17.375 16.597656 17.003906 15.25 17.125 C 14.421875 17.199219 10.65625 17.46875 10.65625 17.46875 C 10.3125 16.953125 7.394531 11.523438 7.03125 10.96875 C 6.675781 10.425781 6.359375 10 5.59375 10 L 3.96875 10 C 3.570313 10 2.261719 10.53125 1.09375 14.78125 C 0.195313 18.058594 0 23 0 23 L 6.5 23 L 6.875 16.9375 C 6.875 16.9375 8.488281 19.328125 8.78125 19.65625 C 9.222656 20.15625 9.300781 20.304688 9.84375 20.21875 C 10.011719 20.191406 11.027344 20.050781 12 19.90625 L 12 21 L 26 21 L 26 19 L 24.15625 19 C 24.167969 18.921875 24.195313 18.859375 24.1875 18.78125 L 23.375 8.90625 L 25.46875 4.21875 C 25.578125 3.972656 25.457031 3.683594 25.21875 3.5625 L 24.53125 3.21875 C 24.472656 3.1875 24.40625 3.160156 24.34375 3.15625 Z" />
);

export default withSVG(FreelancerIcon, {
  viewBox: '0 0 26 26',
  size: '40',
  fill: '#1976d2',
});
