import React from "react";
import { Wrapper, TextField, RichEditor, Button } from "views/ui";
import { withFormik, Form } from "formik";
import { IconButton } from "@material-ui/core";
import { PlaylistAdd } from "@material-ui/icons";

const siteInfoForm = ({ values, setFieldValue, handleChange }) => {
  const handleHtml = markup => {
    setFieldValue("description", markup);
  };

  const handleAddMore = type => {
    switch (type) {
      case "subtitle":
        setFieldValue("subtitles", values.subtitles.concat(""));
        break;
      case "meta":
        setFieldValue("meta", values.meta.concat(""));
        break;
      case "email":
        setFieldValue(
          "emails",
          values.emails.concat({
            label: "",
            value: ""
          })
        );
        break;
      case "phone":
        setFieldValue(
          "phones",
          values.phones.concat({
            label: "",
            value: ""
          })
        );
        break;
      case "copyright":
        setFieldValue("copyrights", values.copyrights.concat(""));
        break;
      case "address":
        setFieldValue("addresses", values.addresses.concat(""));
        break;
      case "extraLink":
        setFieldValue(
          "extraLinks",
          values.extraLinks.concat({
            name: "",
            url: ""
          })
        );
        break;
      default:
        break;
    }
  };

  return (
    <Form>
      <Wrapper p={4}>
        <TextField
          mb={2}
          label="Title"
          margin="dense"
          variant="outlined"
          fullWidth
          name="title"
          value={values.title}
          onChange={handleChange}
        />
        <TextField
          mb={2}
          label="Site URL"
          margin="dense"
          variant="outlined"
          fullWidth
          name="siteUrl"
          value={values.siteUrl}
          onChange={handleChange}
        />

        <Wrapper mb={2} border={1} borderColor="grey.400" borderRadius={4}>
          <RichEditor
            name="content"
            html={values.description}
            handleHtml={handleHtml}
          />
        </Wrapper>

        <Wrapper display="flex" alignItems="center">
          <Wrapper width="95%">
            {values.subtitles.map((subtitle, index) => (
              <TextField
                key={index}
                mb={2}
                label="Subtitle"
                margin="dense"
                variant="outlined"
                fullWidth
                name={`subtitles[${index}]`}
                value={subtitle}
                rows={4}
                onChange={handleChange}
              />
            ))}
          </Wrapper>

          <IconButton onClick={() => handleAddMore("subtitle")}>
            <PlaylistAdd />
          </IconButton>
        </Wrapper>

        <Wrapper display="flex" alignItems="center">
          <Wrapper width="95%">
            {values.meta.map((meta, index) => (
              <TextField
                key={index}
                mb={2}
                label="Meta"
                margin="dense"
                variant="outlined"
                fullWidth
                name={`meta[${index}]`}
                value={meta}
                rows={4}
                onChange={handleChange}
              />
            ))}
          </Wrapper>

          <IconButton onClick={() => handleAddMore("meta")}>
            <PlaylistAdd />
          </IconButton>
        </Wrapper>

        <Wrapper display="flex" alignItems="center">
          <Wrapper width="95%">
            {values.emails.map((email, index) => (
              <Wrapper display="flex" key={index} mb={2}>
                <TextField
                  label="Label"
                  margin="dense"
                  variant="outlined"
                  width="200"
                  name={`emails[${index}].label`}
                  value={email.label}
                  rows={4}
                  mr={1}
                  onChange={handleChange}
                />
                <TextField
                  label="Email"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  name={`emails[${index}].value`}
                  value={email.value}
                  rows={4}
                  onChange={handleChange}
                />
              </Wrapper>
            ))}
          </Wrapper>
          <IconButton onClick={() => handleAddMore("email")}>
            <PlaylistAdd />
          </IconButton>
        </Wrapper>

        <Wrapper display="flex" alignItems="center">
          <Wrapper width="95%">
            {values.phones.map((phone, index) => (
              <Wrapper display="flex" key={index} mb={2}>
                <TextField
                  label="Label"
                  margin="dense"
                  variant="outlined"
                  width="200"
                  name={`phones[${index}].label`}
                  value={phone.label}
                  rows={4}
                  mr={1}
                  onChange={handleChange}
                />
                <TextField
                  label="Phone"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  name={`phones[${index}].value`}
                  value={phone.value}
                  rows={4}
                  onChange={handleChange}
                />
              </Wrapper>
            ))}
          </Wrapper>
          <IconButton onClick={() => handleAddMore("phone")}>
            <PlaylistAdd />
          </IconButton>
        </Wrapper>

        <Wrapper display="flex" alignItems="center">
          <Wrapper width="95%">
            {values.copyrights.map((copyright, index) => (
              <TextField
                key={index}
                mb={2}
                label="Copyright"
                margin="dense"
                variant="outlined"
                fullWidth
                name={`copyrights[${index}]`}
                value={copyright}
                rows={4}
                onChange={handleChange}
              />
            ))}
          </Wrapper>

          <IconButton onClick={() => handleAddMore("copyright")}>
            <PlaylistAdd />
          </IconButton>
        </Wrapper>

        <Wrapper display="flex" alignItems="center">
          <Wrapper width="95%">
            {values.extraLinks.map((link, index) => (
              <Wrapper display="flex" key={index} mb={2}>
                <TextField
                  label="Link Label"
                  margin="dense"
                  variant="outlined"
                  width="200"
                  name={`extraLinks[${index}].label`}
                  value={link.label}
                  rows={4}
                  mr={1}
                  onChange={handleChange}
                />
                <TextField
                  label="Url"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  name={`extraLinks[${index}].url`}
                  value={link.url}
                  rows={4}
                  onChange={handleChange}
                />
              </Wrapper>
            ))}
          </Wrapper>
          <IconButton onClick={() => handleAddMore("extraLink")}>
            <PlaylistAdd />
          </IconButton>
        </Wrapper>

        <Wrapper display="flex" alignItems="center">
          <Wrapper width="95%">
            {values.addresses.map((address, index) => (
              <TextField
                key={index}
                mb={2}
                label="Address"
                margin="dense"
                variant="outlined"
                fullWidth
                name={`addresses[${index}]`}
                value={address}
                multiline
                rows={4}
                onChange={handleChange}
              />
            ))}
          </Wrapper>

          <IconButton onClick={() => handleAddMore("address")}>
            <PlaylistAdd />
          </IconButton>
        </Wrapper>

        <Wrapper display="flex" justifyContent="flex-end" mt={2}>
          <Button color="primary" variant="contained" type="submit">
            Update
          </Button>
        </Wrapper>
      </Wrapper>
    </Form>
  );
};

export default withFormik({
  mapPropsToValues: ({ siteInfo = {} }) => {
    const title = siteInfo.title || "";
    const description = siteInfo.description || "";
    const siteUrl = siteInfo.siteUrl || "";
    const subtitles = siteInfo.subtitles || [""];
    const meta = siteInfo.subtitles || [""];
    const copyrights = siteInfo.copyrights || [""];
    const addresses = siteInfo.addresses || [""];
    const extraLinks = siteInfo.extraLinks || [
      {
        label: "",
        url: ""
      }
    ];
    const emails = siteInfo.emails || [
      {
        label: "",
        value: ""
      }
    ];
    const phones = siteInfo.phones || [
      {
        label: "",
        value: ""
      }
    ];

    return {
      title,
      description,
      siteUrl,
      subtitles,
      meta,
      emails,
      phones,
      copyrights,
      addresses,
      extraLinks
    };
  },
  handleSubmit: (values, { props: { handleSiteInfo } }) => {
    handleSiteInfo(values);
  }
})(siteInfoForm);
