import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { Button, Wrapper, Divider, PreLoader, Typography } from "views/ui";
import ImageDetails from "./ImageDetails";
import ImageThumbnail from "./ImageThumbnail";
import UploadImage from "./UploadImage";
import { useToggle } from "hooks";
import { galleryActions } from "store/gallery";

export default function ImageDetailsContainer() {
  const dispatch = useDispatch();
  const [imageDetails, setImageDetails] = useState(null);
  const [open, handleToggleChange] = useToggle(false);
  const [openUploadImage, setOpenUploadImage] = useToggle(false);
  const { getGalleries } = galleryActions;
  const { loading } = useSelector(({ ui }) => ({
    loading: ui.loading
  }));
  const galleries = useSelector(({ uploads }) => uploads.galleries);

  useEffect(() => {
    dispatch(getGalleries());
  }, [dispatch, getGalleries]);

  const handleImageDetails = index => {
    handleToggleChange();
    if (imageDetails) {
      setImageDetails(null);
    } else {
      setImageDetails(galleries[index]);
    }
  };

  return (
    <Wrapper mt={4}>
      <Wrapper
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography fontSize={18} variant="subtitle1">
          Total images: {galleries.length}
        </Typography>
        <Button
          onClick={setOpenUploadImage}
          color="primary"
          variant="contained"
        >
          Upload Image
        </Button>
      </Wrapper>
      <Divider my={4} />
      {loading.getGalleries ? (
        <PreLoader />
      ) : (
        <Grid container spacing={4}>
          {galleries.map((image, index) => (
            <Grid item xs={2} sm={4} md={3} lg={3} key={image._id}>
              <Wrapper width="150px" mx="auto">
                <ImageThumbnail
                  imageMeta={image.imageMeta}
                  imageId={image.imageId}
                  onClick={() => handleImageDetails(index)}
                />
              </Wrapper>
            </Grid>
          ))}
        </Grid>
      )}

      {imageDetails && (
        <ImageDetails
          open={open}
          handleToggleChange={handleImageDetails}
          image={imageDetails}
          loading={loading.updateImage}
        />
      )}
      <UploadImage
        open={openUploadImage}
        setOpenUploadImage={handleImageDetails}
        handleClose={setOpenUploadImage}
        loading={loading.uploadImage}
      />
    </Wrapper>
  );
}
