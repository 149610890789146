import React from 'react';
import Table from 'views/components/Table';
import Edit from '@material-ui/icons/Edit';

const Features = ({ features, handleToggleChange, handleSelectFeature }) => {
  return (
    <>
      {features.length && (
        <Table
          title={`Showing results ${features.length} of ${features.length}`}
          handleTitleAction={handleToggleChange}
          columns={[
            {
              title: 'Title',
              field: 'title',
            },
            {
              title: 'Description',
              field: 'description',
              html: true,
            },
          ]}
          actions={[
            {
              icon: <Edit />,
              tooltip: 'Edit',
              handleClick: item => handleSelectFeature(item),
              disabled: false,
            },
          ]}
          data={features}
        />
      )}
    </>
  );
};

export default Features;
