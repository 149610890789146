import React from "react";
import format from "date-fns/format";
import { Typography } from "../";

const DateText = ({ date, isSingleLine = false }) => {
  return (
    <>
      {date ? (
        <>
          {isSingleLine ? (
            <Typography>
              {format(new Date(date), "yyyy/MM/dd h:mm:ss a")}
            </Typography>
          ) : (
            <>
              <Typography>{format(new Date(date), "yyyy/MM/dd")}</Typography>
              <Typography variant="subtitle1">
                {format(new Date(date), "h:mm:ss a")}
              </Typography>
            </>
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default DateText;
