export const GET_SITES = 'SITES/GET_SITES';
export const GET_SITES_COMPLETED = 'SITES/GET_SITES_COMPLETED';
export const GET_SITES_FAILED = 'SITES/GET_SITES_FAILED';

export const UPDATE_SITE = 'SITES/UPDATE_SITE';
export const UPDATE_SITE_COMPLETED = 'SITES/UPDATE_SITE_COMPLETED';
export const UPDATE_SITE_FAILED = 'SITES/UPDATE_SITE_FAILED';

export const CREATE_SITE = 'SITES/CREATE_SITE';
export const CREATE_SITE_COMPLETED = 'SITES/CREATE_SITE_COMPLETED';
export const CREATE_SITE_FAILED = 'SITES/CREATE_SITE_FAILED';

export const GET_SITE = 'SITES/GET_SITE';
export const GET_SITE_COMPLETED = 'SITES/GET_SITE_COMPLETED';
export const GET_SITE_FAILED = 'SITES/GET_SITE_FAILED';
