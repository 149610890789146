import React, { useEffect, useState } from 'react';
import BlogList from './BlogList';
import { useDispatch, useSelector } from 'react-redux';
import { blogActions } from 'store/blogs';
import { jenkinsActions } from 'store/jenkins';
import { usePagination, useToggle } from 'hooks';
import { Confirm } from 'views/ui';

const BlogContainer = () => {
  const dispatch = useDispatch();
  const { getBlogs, deleteBlog } = blogActions;
  const { buildSite } = jenkinsActions;
  const blogs = useSelector(({ blogs }) => blogs.blogs);
  const { page, limit, handleChangePage, handleChangeLimit } = usePagination();
  const [openConfirmDialog, setOpenConfirmDialog] = useToggle(false);
  const [blogId, setBlogId] = useState(null);
  const [siteBuildInProgress, setSiteBuildInProgress] = useState(null);

  useEffect(() => {
    dispatch(
      getBlogs({
        page,
        limit,
      })
    );
  }, [dispatch, getBlogs, page, limit]);

  useEffect(() => {
    if (siteBuildInProgress) {
      setTimeout(() => {
        setSiteBuildInProgress(false);
      }, 60000); // 1 minutes
    }
  }, [siteBuildInProgress]);

  const handleOpenConfirmDialog = (id) => {
    setBlogId(id);
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setBlogId(null);
    setOpenConfirmDialog(false);
  };

  const handleConfirm = () => {
    dispatch(deleteBlog(blogId));
    setOpenConfirmDialog(false);
  };

  const handleBuildSite = () => {
    dispatch(
      buildSite({
        site: 'orocubeBlog',
      })
    );

    setSiteBuildInProgress(true);
  };

  return (
    <>
      <BlogList
        blogs={blogs}
        page={page}
        limit={limit}
        handleChangePage={handleChangePage}
        handleChangeLimit={handleChangeLimit}
        handleConfirmDialog={handleOpenConfirmDialog}
        handleBuildSite={handleBuildSite}
        siteBuildInProgress={siteBuildInProgress}
      />
      <Confirm
        open={openConfirmDialog}
        handleClose={handleCloseConfirmDialog}
        handleConfirm={handleConfirm}
      />
    </>
  );
};

export default BlogContainer;
