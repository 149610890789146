import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { Wrapper, Image, Button, Dialog, TextField } from "views/ui";
import { variables } from "helpers";

const ImageDetails = ({
  details,
  open,
  loading,
  handleToggleChange,
  handleImageUpdate,
  handleImageDelete
}) => {
  const [title, setTitle] = useState("");

  useEffect(() => {
    setTitle(details.title);
  }, [details.title]);

  const handleSave = () => {
    handleImageUpdate(details._id, title);
  };

  const handleReject = () => {
    handleImageDelete(details._id);
  };

  const handleCopy = () => {
    const path = document.getElementById("path-for-copy");
    path.select();

    document.execCommand("copy");
  };

  return (
    <Dialog
      open={open}
      handleClose={handleToggleChange}
      handleSave={handleSave}
      handleReject={handleReject}
      btnDisabled={loading}
      loading={loading}
    >
      <Grid container>
        <Grid item xs={8}>
          <Image src={variables.apiEndpoint + details.path} />
        </Grid>
        <Grid item xs={4}>
          <Wrapper px={2}>
            <TextField
              value={title}
              fullWidth
              variant="outlined"
              margin="dense"
              mb={2}
              onChange={e => setTitle(e.target.value)}
            />
            <Wrapper>
              <TextField
                value={variables.apiEndpoint + details.path}
                fullWidth
                id="path-for-copy"
              />
              <Button
                color="primary"
                variant="outlined"
                size="small"
                mt={1}
                onClick={handleCopy}
              >
                Copy
              </Button>
            </Wrapper>
          </Wrapper>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ImageDetails;
