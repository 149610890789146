import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Typography, Wrapper } from "../";

const DialogHeader = ({ children, onClose, ...rest }) => {
  return (
    <DialogTitle disableTypography {...rest}>
      <Grid container>
        <Grid item xs={11}>
          <Typography variant="h6">{children}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Wrapper textAlign="right">
            {onClose && (
              <IconButton aria-label="close" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            )}
          </Wrapper>
        </Grid>
      </Grid>
    </DialogTitle>
  );
};

export default function DialogExtended({
  label = "untitled-dialog",
  open,
  heading,
  handleClose,
  children,
  maxWidth = "md"
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby={label}
      open={open}
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth={maxWidth}
    >
      <DialogHeader id="customized-dialog-title" onClose={handleClose}>
        {heading}
      </DialogHeader>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  );
}
