import { Drawer } from "@material-ui/core";
import styled from "styled-components";

const DrawerExtended = styled(Drawer)`
  .MuiDrawer-paper {
    width: 60vw;
  }
`;

export default DrawerExtended;
