import produce from "immer";
import * as types from "./types";

const initialState = {
  galleries: [],
  image: {}
};

const uploadReducers = function (state = initialState, action) {
  const { type, payload } = action;

  return produce(state, draft => {
    switch (type) {
      case types.GET_GALLERIES_COMPLETED:
        draft.galleries = payload;
        break;
      case types.UPLOAD_IMAGE_COMPLETED:
        draft.galleries = [payload, ...state.galleries];
        draft.image = payload;
        break;
      case types.DOWNLOAD_IMAGE_COMPLETED:
        draft.galleries = [payload, ...state.galleries];
        break;
      case types.UPDATE_MULTIPLE_IMAGES_COMPLETED:
        draft.galleries = [...payload, ...state.galleries];
        break;
      case types.UPDATE_IMAGE_COMPLETED:
        const imageIndex = state.galleries.findIndex(
          image => image._id === payload._id
        );
        draft.galleries[imageIndex] = payload;
        break;
      case types.DELETE_IMAGE_COMPLETED:
        draft.galleries = draft.galleries.filter(
          image => image._id !== payload._id
        );
        break;
      default:
        return state;
    }
  });
};

export default uploadReducers;
