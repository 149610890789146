import React from 'react';
import { InlineDatepicker } from './';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  date: {
    borderRadius: 3,
    cursor: 'pointer',
    padding: '1px 10px',
    border: '1px solid rgba(0, 0, 0, 0.23);',
    width: 120,
    color: '#3C4252',

    '& > div:before': {
      display: 'none',
    },
    '& > div:after': {
      display: 'none',
    },
    '& input': {
      textAlign: 'center',
      cursor: 'pointer',
    },
  },
};

const SingleDate = ({ name, value, handleDateChange, classes }) => {
  return (
    <InlineDatepicker
      className={classes.date}
      autoOk
      name={name}
      clearable={true}
      disableFuture
      format="MM/dd/yyyy"
      value={value}
      onChange={handleDateChange}
    />
  );
};

export default withStyles(styles)(SingleDate);
