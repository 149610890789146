import React from "react";
import { Typography, Wrapper, Confirm } from "views/ui";
import { IconButton, Card, CardActions, CardContent } from "@material-ui/core";
import { Button } from "views/ui";
import { Delete, Edit, Link } from "@material-ui/icons";
import { useToggle } from "hooks";

const SubSection = ({
  subSection = {},
  handleSubSectionEdit,
  handleSubSectionDelete,
  showEdit = true
}) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useToggle(false);

  const handleConfirm = () => {
    handleSubSectionDelete(subSection.id);
    setOpenConfirmDialog(false);
  };

  return (
    <Card>
      {showEdit && (
        <Wrapper display="flex" justifyContent="flex-end">
          <IconButton
            aria-label="delete"
            onClick={() => handleSubSectionEdit(subSection)}
          >
            <Edit />
          </IconButton>
          <IconButton aria-label="delete" onClick={setOpenConfirmDialog}>
            <Delete />
          </IconButton>
        </Wrapper>
      )}
      <CardContent>
        <Typography variant="h5" component="h2">
          {subSection.title}
        </Typography>
        {subSection.subtitles &&
          subSection.subtitles.map((subtitle, index) => (
            <Typography variant="subtitle1" mb={1} key={index}>
              {subtitle}
            </Typography>
          ))}
        <Typography
          variant="body2"
          dangerouslySetInnerHTML={{ __html: subSection.description }}
        ></Typography>
      </CardContent>
      <CardActions>
        {subSection.links &&
          subSection.links.map((link, index) => (
            <>
              {link && (
                <Button size="small" key={index} href={link.url}>
                  <Link /> &nbsp; {link.label}
                </Button>
              )}
            </>
          ))}
      </CardActions>

      <Confirm
        open={openConfirmDialog}
        handleClose={setOpenConfirmDialog}
        handleConfirm={handleConfirm}
      />
    </Card>
  );
};

export default SubSection;
