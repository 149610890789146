export const GET_FEATURES = 'FEATURES/GET_FEATURES';
export const GET_FEATURES_COMPLETED = 'FEATURES/GET_FEATURES_COMPLETED';
export const GET_FEATURES_FAILED = 'FEATURES/GET_FEATURES_FAILED';

export const UPDATE_FEATURE = 'FEATURES/UPDATE_FEATURE';
export const UPDATE_FEATURE_COMPLETED = 'FEATURES/UPDATE_FEATURE_COMPLETED';
export const UPDATE_FEATURE_FAILED = 'FEATURES/UPDATE_FEATURE_FAILED';

export const CREATE_FEATURE = 'FEATURES/CREATE_FEATURE';
export const CREATE_FEATURE_COMPLETED = 'FEATURES/CREATE_FEATURE_COMPLETED';
export const CREATE_FEATURE_FAILED = 'FEATURES/CREATE_FEATURE_FAILED';
