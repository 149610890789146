import produce from 'immer';
import * as types from './types';

const initialState = {
  features: [],
};

const featureReducers = function(state = initialState, action) {
  const { type, payload } = action;

  return produce(state, draft => {
    switch (type) {
      case types.GET_FEATURES_COMPLETED:
        draft.features = payload;
        break;
      case types.CREATE_FEATURE_COMPLETED:
        draft.features.unshift(payload);
        break;
      case types.UPDATE_FEATURE_COMPLETED:
        const featureIndex = state.features.findIndex(
          feature => feature._id === payload._id
        );
        draft.features[featureIndex] = payload;
        break;
      default:
        return state;
    }
  });
};

export default featureReducers;
