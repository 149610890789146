import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Wrapper, Image } from "views/ui";
import placeholder from "assets/images/placeholder.png";
import { galleryActions } from "store/gallery";
import { variables } from "helpers";
import { getPath } from "../ImageDetails/getImageInfo";

export default function UploadThumbnail({ handleThumbnail, url = "" }) {
  const dispatch = useDispatch();
  const { uploadImage } = galleryActions;
  const [imageUrl, setImageUrl] = useState(url);

  const handleUploadImage = async e => {
    const file = e.target.files[0];
    if (file.type.indexOf("image/") === 0) {
      const formData = new FormData();
      formData.append("file", file);

      const response = await dispatch(uploadImage(formData));

      if (response.status === "success" && response.data.imageId) {
        const imageId = response.data.imageId;
        const path = getPath(response.data.imageMeta, "original");
        const imageUrl = `${variables.assetsEndpoint}/${imageId}/${
          imageId + path
        }`;
        setImageUrl(imageUrl);
        handleThumbnail(imageUrl);
      }
    }
  };

  const handleRemoveImage = () => {
    setImageUrl("");
    handleThumbnail("");
  };

  return (
    <>
      <Wrapper mb={2} borderBottom={1} pb={2}>
        <Image src={imageUrl ? imageUrl : placeholder} />
      </Wrapper>
      <Wrapper display="flex">
        {imageUrl && (
          <Button
            size="small"
            color="primary"
            variant="outlined"
            onClick={handleRemoveImage}
            mr={1}
          >
            Remove
          </Button>
        )}
        <input
          accept="image/*"
          style={{ display: "none" }}
          id="contained-button-file"
          multiple
          type="file"
          onChange={handleUploadImage}
        />
        <label htmlFor="contained-button-file">
          <Button
            variant="contained"
            color="primary"
            component="span"
            size="small"
          >
            {imageUrl ? "Change" : "Upload Thumbnail"}
          </Button>
        </label>
      </Wrapper>
    </>
  );
}
