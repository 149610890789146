import React, { useState, useEffect } from "react";
import { Link } from "@material-ui/core";
import { Done } from "@material-ui/icons";
import { Wrapper, Typography, Paper, DateText, Button } from "views/ui";
import Table from "../Table";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import DownloadIcon from "../../ui/icons/DownloadIcon";
import styled from "styled-components";

const SubRow = styled.div`
  background: #f9f9f9;
  margin: 16px;
  margin-left: 84px;
  padding: 16px;
`;

const MetaBg = styled.p`
  span {
    background: ${({ type, color }) =>
      type === "priority" ? "transparent" : color};
    border-radius: 2px;
    color: ${({ type, color }) => (type === "priority" ? color : "#fff")};
    text-transform: capitalize;
    padding: 0px 4px;
  }
`;

export const productInitials = {
  oropos: "OP",
  floreant: "FL",
  both: "BT"
};

export const productColors = {
  oropos: "#1A98D8",
  floreant: "#F4BB40",
  both: "#C4B9FF"
};

const TableWrapper = styled.div`
  width: ${({ width }) => width}px;

  .MuiTableCell-root {
    padding: 0 4px;
  }
`;

export default function Leads({
  leads: { list, total },
  handleOpenEmailDialog,
  handleOpenEmailSentDialog,
  handlePagination,
  pageCount,
  pageIndex,
  pageSize,
  filter = "email",
  handleRowSelection
}) {
  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(window.innerWidth - 178);
  }, []);

  const columns = React.useMemo(
    () => [
      {
        // Make an expander cell
        Header: () => null, // No header
        id: "expander", // It needs an ID
        Cell: ({ row }) => (
          // Use Cell to render an expander for each row.
          // We can use the getToggleRowExpandedProps prop-getter
          // to build the expander.
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
          </span>
        )
      },
      {
        Header: filter,
        accessor: filter
      },
      {
        Header: "Count",
        accessor: "count"
      }
    ],
    [filter]
  );

  const LeadsColumns = React.useMemo(
    () => [
      {
        // Make an expander cell
        Header: () => null, // No header
        id: "expander", // It needs an ID
        Cell: ({ row }) => (
          // Use Cell to render an expander for each row.
          // We can use the getToggleRowExpandedProps prop-getter
          // to build the expander.
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
          </span>
        )
      },
      {
        Header: "Full name",
        accessor: "fullName"
      },
      {
        Header: "Email",
        accessor: "email"
      },
      {
        Header: "Email Count",
        accessor: "emailCount"
      },
      {
        Header: "Created At",
        accessor: lead => <DateText date={lead.createdAt} />
      },
      {
        Header: "Phone",
        accessor: lead => (
          <Wrapper display="flex">
            {lead.status === "byPhone" || lead.status === "both" ? (
              <Wrapper width={18}>
                <Done style={{ width: 15, height: 15 }} />
              </Wrapper>
            ) : (
              <Wrapper width={0} />
            )}
            <Wrapper width="160px">
              <Typography variant="body1">
                {lead.Country && lead.Country.dialCode} {lead.phone}
              </Typography>
            </Wrapper>
          </Wrapper>
        )
      },
      { Header: "Country", accessor: "Country.name" },
      {
        Header: "Product Type",
        accessor: lead => (
          <MetaBg type="status" color={productColors[lead.product]}>
            {lead.product ? <span>{productInitials[lead.product]}</span> : ""}
          </MetaBg>
        )
      },
      {
        Header: "Business Name",
        accessor: lead => (
          <>
            <Typography>{lead.businessName}</Typography>
            <Typography>{lead.businessType}</Typography>
          </>
        )
      },
      {
        Header: "IP Address",
        accessor: lead => (
          <Link
            color="textPrimary"
            target="_blank"
            href={`https://tools.keycdn.com/geo?host=${lead.IPAddress}`}
          >
            <Typography variant="body1">{lead.IPAddress}</Typography>
          </Link>
        )
      },
      { Header: "Previous POS", accessor: "prevPOS" },
      { Header: "Show online Demo", accessor: "showOnline" },
      {
        Header: "Need Card Processing",
        accessor: lead => (lead.needCardProcessing ? "Yes" : "No")
      },
      { Header: "Monthly Volume", accessor: "monthlyVolume" },
      { Header: "Average Transaction", accessor: "averageTransaction" },
      {
        Header: "Type",
        accessor: lead => <DownloadIcon />
      }
    ],
    []
  );

  const mailColumns = React.useMemo(
    () => [
      {
        Header: "Type",
        accessor: "type"
      },
      {
        Header: "Level",
        accessor: "level"
      },
      {
        Header: "TemplateId",
        accessor: "templateId"
      },
      {
        Header: "Sent at",
        accessor: mail => <DateText date={mail.createdAt} />
      },
      { Header: "Sent", accessor: mail => (mail.sent ? "Yes" : "No") },
      { Header: "Open", accessor: mail => (mail.open ? "Yes" : "No") },
      { Header: "Click", accessor: mail => (mail.click ? "Yes" : "No") },
      { Header: "Spam", accessor: mail => (mail.smap ? "Yes" : "No") },
      { Header: "Bounce", accessor: mail => (mail.bounce ? "Yes" : "No") },
      { Header: "Blocked", accessor: mail => (mail.blocked ? "Yes" : "No") },
      {
        Header: "Unsubscribe",
        accessor: mail => (mail.unsubscribe ? "Yes" : "No")
      }
    ],
    []
  );

  const renderMailSubComponent = React.useCallback(
    ({ row }) => {
      if (!row.original) {
        return;
      }

      return (
        <>
          {row?.original?.ScheduledMails?.length ? (
            <SubRow>
              <Table
                columns={mailColumns}
                data={row?.original?.ScheduledMails ?? []}
                hasPagination={false}
              />
            </SubRow>
          ) : (
            <Typography>No Data</Typography>
          )}
        </>
      );
    },
    [mailColumns]
  );

  const renderRowSubComponent = React.useCallback(
    ({ row }) => {
      if (!row.original) {
        return;
      }
      return (
        <SubRow>
          <Table
            columns={LeadsColumns}
            data={row?.original?.details ?? []}
            hasPagination={false}
            renderRowSubComponent={renderMailSubComponent}
          />
        </SubRow>
      );
    },
    [LeadsColumns, renderMailSubComponent]
  );

  return (
    <Wrapper>
      <Wrapper
        display="flex"
        alignItems="center"
        mb={3}
        justifyContent="space-between"
      >
        <Typography variant="h4" mr={2}>
          Total Leads: {total}
        </Typography>
        <Wrapper display="flex">
          <Button
            onClick={handleOpenEmailSentDialog}
            color="primary"
            size="small"
            variant="outlined"
            ml={2}
            mr={2}
          >
            Send Emails
          </Button>
        </Wrapper>
      </Wrapper>
      {list.length ? (
        <TableWrapper width={width}>
          <Paper py={2}>
            <Table
              isSelectable={true}
              columns={columns}
              data={list}
              pageCount={pageCount}
              handlePagination={handlePagination}
              renderRowSubComponent={renderRowSubComponent}
              pageIndex={pageIndex}
              pageSize={pageSize}
              handleRowSelection={handleRowSelection}
            />
          </Paper>
        </TableWrapper>
      ) : (
        <Paper p={4}>
          <Typography align="center" variant="body1">
            No Lead found!
          </Typography>
        </Paper>
      )}
    </Wrapper>
  );
}
