import React from "react";
import Signup from "views/components/Signup";
import { Wrapper, Paper, Typography } from "views/ui";

const SignupPage = () => (
  <Wrapper width={{ sm: "60%", md: "40%", lg: "30%" }} mx="auto" mt="20vh">
    <Paper p={8}>
      <Typography variant="h2" align="center" mb={3}>
        Signup Form
      </Typography>
      <Signup />
    </Paper>
  </Wrapper>
);

export default SignupPage;
