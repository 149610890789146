import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Wrapper, Image, Divider, PreLoader, Button } from "views/ui";
import ImageDetails from "./ImageDetailsV1";
import styled from "styled-components";
import { useToggle } from "hooks";
import { uploadActions } from "store/uploads";
import { variables } from "helpers";

const ImageWrapper = styled.div`
  border: 1px solid #eee;
  margin: 10px;
  display: flex;
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
`;

export default function ImageDetailsContainer() {
  const dispatch = useDispatch();
  const [imageDetails, setImageDetails] = useState(null);
  const [open, handleToggleChange] = useToggle(false);
  const { getGalleries, deleteImage, updateImage } = uploadActions;
  const { loading } = useSelector(({ ui }) => ({
    loading: ui.loading
  }));
  const galleries = useSelector(({ uploads }) => uploads.galleries);

  useEffect(() => {
    dispatch(getGalleries());
  }, [dispatch, getGalleries]);

  const handleImageDetails = index => {
    handleToggleChange();
    if (imageDetails) {
      setImageDetails(null);
    } else {
      setImageDetails(galleries[index]);
    }
  };

  const handleImageDelete = id => {
    dispatch(deleteImage(id));
    handleToggleChange();
  };

  const handleImageUpdate = (id, title) => {
    const data = {
      title
    };
    dispatch(updateImage(id, data));
  };

  return (
    <Wrapper>
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/galleries-v2"
      >
        Upload Image
      </Button>
      <Divider my={8} />
      {loading.getGalleries ? (
        <PreLoader />
      ) : (
        <Grid container>
          {galleries.map((image, index) => (
            <Grid item xs={2} key={image._id}>
              <ImageWrapper>
                <Image
                  src={variables.apiEndpoint + image.path}
                  alt={image.title}
                  onClick={() => handleImageDetails(index)}
                />
              </ImageWrapper>
            </Grid>
          ))}
        </Grid>
      )}

      {imageDetails && (
        <ImageDetails
          open={open}
          handleToggleChange={handleImageDetails}
          handleImageDelete={handleImageDelete}
          handleImageUpdate={handleImageUpdate}
          details={imageDetails}
          loading={loading.updateImage}
        />
      )}
    </Wrapper>
  );
}
